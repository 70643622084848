/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import {
	Alert,
	Breadcrumb,
	Button,
	DatePicker,
	Input,
	Loading,
	Table as TableComponent,
	TabsPanel,
	Combobox,
	SchedulerModal,
	ViewsModal,
	ConfirmationModal,
	LabelSection,
	ProducerSelectorEos
} from "../../Components";
import Actions from "../../Components/GridComponents/Actions";
import 'react-dates/initialize';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { GLOBAL } from "../../Config/global";
import { FORM_DATA } from "../../Config/form_data";
import "font-awesome/css/font-awesome.min.css";
import { APPCONFIG } from '../../app.config';
import moment from "moment";
import { loadProducerCode } from '../../Config/producerData';
import { truncate } from '../../Shared/Utils';
import userAuth from "../../Config/userauth";
import * as QLIBS from '../../qLibsHelpers';
import { DEFAULT_HEADER } from '../../Config/userToken';
import { PolicyInfoModal } from './policyInfoModal';
import PolicyRowDetail from './partials/policyRowDetail';
import { APIS } from '../../Config/apis';
import { ErrorLevel } from '../../Components/Alert/errorLevel';

class Cartera extends React.Component {
	constructor(props) {
		super(props);

		this.filterTabs = [
			{
				label: 'Filtro por Productor'
			},
			{
				label: 'Filtro por Fecha'
			},
			{
				label: 'Filtro por Producto'
			},
			{
				label: 'Filtro por Póliza'
			},
			{
				label: 'Filtro por Cliente'
			}
		];
		this.state = {
			tableData: [],
			ramos: [],
			productos: [],
			productosLabel: [],
			name: '',
			checkedItems: [],
			productorSelected: 0,
			showImprimeAsegurados: false,
			showPolicyInfoModal: false,
			showViewsModal: false,
			selectedRow: null,
			poliza: null,
			enableFilterTabs: false,
			loadingData: false,
			certificadoGenerado: false,
			searchEnabled: true,
			filtersSelected: true,
			filterTabActive: undefined,
			filters: [],
			sort: [],
			totalesPrima: 0,
			totalesPremio: 0,
			totalesPremioUsd: 0,
			totalesPrimaUsd: 0,
			searchCriteria: [
				{
					"productores": []
				},
				{
					"fechaDesde": null,
					"fechaHasta":new Date(new Date().setHours(0, 0, 0, 0))
				},
				{
					"ramo": '',
					"producto": ''
				},
				{
					"poliza": null,
					"certificado": null,
					"estado": FORM_DATA.STATUS[0].id
				},
				{
					"nombre": null,
					"apellido": null,
					"dni": null
				}
			],
			searchCriteriaText: "",
			productoresData: [],
			showSchedulerModal: false,
			iconFilter: 'fa fa-eye',
			showFilters: false
		};
		this.logoutModalVisible = false;
		this.producerSelector = React.createRef();
		this.showResults = false;
		this.page = 0;
		this.pageSize = 10;
		this.pages = 0;
		this.totalItems = 0;
		this.searchResultsError = "";

		this.searchTableDownloadColumn = {
			Header: "Certificado",
			accessor: "actions",
			width: 100,
			style: {
				display: 'flex',
				alignItems: 'center'
			},
			filterable: false,
			sortable: false,
			resizable: true,
			Cell: row => (
				<Actions
					disabled={row.original.core !== 'VT'}
					icon="fas fa-file-download"
					onDownload={() => this.downloadItem(row)}
				/>
			)
		};

		this.detailsColumn = {
			Header: "Detalles",
			accessor: "actions",
			width: 75,
			style: {
				display: 'flex',
				alignItems: 'center'
			},
			filterable: false,
			sortable: false,
			resizable: true,
			Cell: row => (
				<Actions
					icon="fas fa-info-circle"
					onDownload={() => this.detailsItem(row)}
				/>
			)
		};

		this.baseTableColumns = this.createBaseTableColumns();
		this.searchTableColumns = this.createSearchTableColumns();

		this.downloadItem = this.downloadItem.bind(this);
		this.onChangeTab = this.onChangeTab.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.clearFiltros = this.clearFiltros.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.handleProducerIdChanged = this.handleProducerIdChanged.bind(this);
		this.handleCheckedItemsChanged = this.handleCheckedItemsChanged.bind(this);
		this.handleProducersChanged = this.handleProducersChanged.bind(this);
		this.handleSaveViewModal = this.handleSaveViewModal.bind(this);
		this.onDismissImprimeAsegurados = this.onDismissImprimeAsegurados.bind(this);
		this.onConfirmImprimeAsegurados = this.onConfirmImprimeAsegurados.bind(this);
	}

	componentDidMount() {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
		var userId = userAuth.userId();
		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};
		this.getInitialData()
		this.getTotales()
		return fetch(PRODUCERURL + "api/producer/GetProducerCodes?userId=" + userId, requestOptions)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				var uc = data;
				for (var u in uc) {
					var item = uc[u];
					for (var p in item.producerCodes) {
						var pc = item.producerCodes[p];
						pc.name = pc.name.split("|")[0];
					}
				}
				this.handleProducersChanged(loadProducerCode(uc));
			});
	}
	handleResponseOk = (response, fileName) => {
		let self = this;
		response.blob()
			.then(function (blob) {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = fileName;
				link.click();
			})
			.then(() => {
				self.setState({ certificadoGenerado: true, loadingData: false, item: self.itemInit, showImprimeAsegurados: false });
			});
	}
	handleResponseError = (response) => {
		let self = this;
		self.showResults = true;
		self.searchResultsError = "Error al intentar descargar el certificado.";
		response.text()
			.then(function (message) {
				self.setState({
					loadingData: false,
					error: message,
				});

			})
	}
	getFilter() {
		let filters = this.state.filters
		return filters
	}
	getSort() {
		let sort = this.state.sort
		return sort
	}
	applySort = (event) => {
		if (event.length > 0) {
			this.setState({
				sort: event,
			}, () => this.handleSubmit());
		}
		else {
			this.setState({
				sort: {},
			}, this.handleSubmit);
		}
	}
	changeSize = async (size) => {
		this.pageSize = size;
		this.handleSubmit()
	}
	changePage = async (page) => {
		this.page = page;
		this.handleSubmit()
	}

	getCertificadoIndividual = (event) => {
		this.setState({ loadingData: true });
		let fileName = `${event.original.productDescription.replaceAll(/\s/g, '')}_Certificado.pdf`
		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: JSON.stringify({
				idArticulo: event.original.productCodeVt,
				idOperacion: event.original.certificateNumber,
				DNI: event.original.recruiterDni,
				idPoliza: event.original.policyNumberVt,
				fechaExpiracion: event.original.endDate.toString()
			})
		};
		const PRODUCERURL = APIS.URLS.DOWNLOAD_CERTIFICADO_INDIVIDUAL;
		fetch(`${PRODUCERURL}`, requestOptions)
			.then(response => {
				if (response.ok)
					this.handleResponseOk(response, fileName);
				else
					this.handleResponseError(response);
			})
			.catch(error => {
				this.setState({ error: `Error: ${error.message}` });
			});
	}

	getPolizaMatriz(poliza, imprimeAsegurados) {
		this.setState({ loadingData: true });
		const PRODUCERURL = APIS.URLS.DOWNLOAD_CERTIFICADO_MATRIZ;
		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get(),
		};
		fetch(`${PRODUCERURL}?poliza=${poliza}&imprimeAsegurados=${imprimeAsegurados}`, requestOptions)
			.then(response => {
				if (response.ok)
					this.handleResponseOk(response, 'poliza-matriz-' + poliza);
				else
					this.handleResponseError(response);
			})
			.catch(error => {
				this.setState({ error: `Error: ${error.message}`, loadingData: false });
			});
	}

	downloadItem = event => {
		if (event.original.policyType === 'COLECTIVA')
			this.setState({ showImprimeAsegurados: true, poliza: event.original.policyNumberVt });
		else
			this.getCertificadoIndividual(event)
	};

	onDismissImprimeAsegurados = () => {
		this.getPolizaMatriz(this.state.poliza, false)
	}
	onConfirmImprimeAsegurados = () => {
		this.getPolizaMatriz(this.state.poliza, true)
	}

	detailsItem = event => {
		this.setState({ selectedRow: event.row._original, showPolicyInfoModal: true });
	};

	onChangeTab = index => {
		this.setState({ name: this.state.name, filterTabActive: index, filtersSelected: (index > 0) });
	};

	/// Aplica filtros a nivel frontend
	applyFilter = (event) => {
		if (event.length > 0) {
			this.page = 0
			this.setState({
				filters: event,
			});
		}
		else {
			this.setState({
				filters: [],
			});
		}
	}
	handleKeyDown = event => {
		// Chek which field
		switch (event.target.id) {
			case "nro_certificado":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
					event.preventDefault();
				}
				break;

			case "nombre":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
					event.preventDefault();
				}
				break;

			case "apellido":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
					event.preventDefault();
				}
				break;

			case "dni":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
					event.preventDefault();
				}
				break;

			default:
				// Do Nothing
				break;
		}
	};

	handleComboChange = (selectedOption, event) => {
		let inputName = event.name;
		let inputValue = selectedOption.value;
		//let inputString = selectedOption.label;
		let searchCriteria = this.state.searchCriteria;
		this.page = 0;
		switch (inputName) {
			case "ramo":
				searchCriteria[2].ramo = inputValue;
				break;
			case "producto":
				searchCriteria[2].producto = inputValue;
				break;
			case "estado":
				searchCriteria[3].estado = inputValue;
				break;

			default:
				break;
		}
		this.setState({
			...this.state,
			searchCriteria: searchCriteria,
		}, () => {
			this.refreshFiltros();
		});
	};

	handleChange = event => {
		let searchCriteria = this.state.searchCriteria;
		this.page = 0;
		// Check which field
		switch (event.target.id) {
			case "fecha_emision_desde":
				searchCriteria[1].fechaDesde = event.target.value;
				break;

			case "fecha_emision_hasta":
				searchCriteria[1].fechaHasta = event.target.value;
				break;

			case "nro_poliza":
				searchCriteria[3].poliza = event.target.value;
				break;

			case "nro_certificado":
				searchCriteria[3].certificado = event.target.value;
				break;

			case "nombre":
				searchCriteria[4].nombre = event.target.value;
				break;

			case "apellido":
				searchCriteria[4].apellido = event.target.value;
				break;

			case "dni":
				searchCriteria[4].dni = event.target.value;
				break;

			default:
				break;
		}

		this.setState({
			searchCriteria: searchCriteria
		});
	};

	refreshFiltros = () => {
		// Add productores
		const filter = {};
		// creo dinamicamente el objeto para el filtro de acuerdo al filtro seleccionado
		if (this.state.searchCriteria[0].productores.length > 0) {
			filter.ProducerId = {};
			filter.ProducerId.in = [];
			filter.Source = {};
			filter.Source.in = [];
		} else {
			delete filter.ProducerId;
			delete filter.Source;
		}


		if (this.state.searchCriteria[2].ramo) {
			filter.BranchCode = {};
			filter.BranchCode.in = [];
			this.state.searchCriteria[2].ramo.split(';').forEach(function (value, index) {
				filter.BranchCode.in.push(parseInt(value));
			});
		} else {
			delete filter.BranchCode;
		}

		if (this.state.searchCriteria[2].producto) {
			filter.ProductCode = Number(this.state.searchCriteria[2].producto);
		} else {
			delete filter.ProductCode;
		}

		if (this.state.searchCriteria[3].poliza) {
			filter.PolicyNumber = parseInt(this.state.searchCriteria[3].poliza);
		} else {
			delete filter.PolicyNumber;
		}

		if (this.state.searchCriteria[3].certificado) {
			filter.CertificateNumber = parseInt(this.state.searchCriteria[3].certificado);
		} else {
			delete filter.CertificateNumber;
		}

		if (this.state.searchCriteria[3].estado !== '-1') {
			let idestado = this.state.searchCriteria[3].estado;
			let estado = FORM_DATA.STATUS.filter(function (value) { return value.id === idestado })[0];
			//filter.Status = estado.value;
			filter.StatusWebProducers = estado.value;
		} else {
			//delete filter.Status;
			delete filter.StatusWebProducers;
		}

		if (this.state.searchCriteria[4].dni) {
			filter.RecruiterDni = {};
			filter.RecruiterDni.contains = this.state.searchCriteria[4].dni;
		} else {
			delete filter.RecruiterDni;
		}

		this.setState({ name: this.state.name, filterData: filter });
	};

	clearFiltros = () => {
		const SEARCH_DEFAULT_VALUES = [
			{
				"productores": []
			},
			{
				"fechaDesde": null,
				"fechaHasta":new Date(new Date().setHours(0, 0, 0, 0))
			},
			{
				"ramo": '',
				"producto": ''
			},
			{
				"poliza": null,
				"certificado": null,
				"estado": FORM_DATA.STATUS[0].id
			},
			{
				"nombre": null,
				"apellido": null,
				"dni": null
			}
		];
		document.getElementById('fecha_emision_desde').value = '';
		document.getElementById('fecha_emision_hasta').value = moment(new Date()).format("DD/MM/YYYY");
		document.getElementById('nro_poliza').value = '';
		document.getElementById('nro_certificado').value = '';
		document.getElementById('nombre').value = '';
		document.getElementById('apellido').value = '';
		document.getElementById('dni').value = '';
		this.page = 0;
		this.showResults = false;
		this.searchResultsError = false;
		this.setState({
			certificadoGenerado: false,
			searchCriteria: SEARCH_DEFAULT_VALUES,
			checkedItems: [],
			productorSelected: 0,
			filters: [],
			enableFilterTabs: false
		}, () => {
			this.refreshFiltros();
			this.handleSubmit()
		});
	};

	async getInitialData() {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
		this.setState({ name: this.state.name, filterTabActive: -1, loadingData: true });
		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: JSON.stringify({
				producerCuit: userAuth.isGestor() ? userAuth.reportTo() :  userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer(),
				next: this.pageSize,
				fechaDesde: this.state.searchCriteria[1].fechaDesde,
				fechaHasta: this.state.searchCriteria[1].fechaHasta,
				estado: this.state.searchCriteria[3].estado !== '-1' ? parseInt(this.state.searchCriteria[3].estado) : null,
			})
		};
		var url = new URL(`${PRODUCERURL}portfolio/`);

		await fetch(url, requestOptions)
			.then((response) => {
				return QLIBS.authorizeAction(response);
			})
			.then(this.handleResponse)
			.catch(this.handleResponse);


	}

	getTotales = async () => {
		this.setState({ loadingTotales: true })
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: JSON.stringify({
				producerCuit: userAuth.isGestor() ? userAuth.reportTo() :  userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer(),
				producerId: this.state.searchCriteria[0].productores.map(x => parseFloat(x.id.replace('X', ''))),
				next: this.pageSize,
				fechaDesde: this.state.searchCriteria[1].fechaDesde,
				fechaHasta: this.state.searchCriteria[1].fechaHasta,
				ramo: this.state.searchCriteria[2].ramo,
				producto: this.state.searchCriteria[2].producto,
				poliza: this.state.searchCriteria[3].poliza,
				certificado: this.state.searchCriteria[3].certificado,
				nombre: this.state.searchCriteria[4].nombre,
				apellido: this.state.searchCriteria[4].apellido,
				dni: this.state.searchCriteria[4].dni,
				estado: this.state.searchCriteria[3].estado !== '-1' ? parseInt(this.state.searchCriteria[3].estado) : null,
			})
		};
		var urlTotales = new URL(`${PRODUCERURL}portfolio/totales`);
		return fetch(urlTotales, requestOptions)
			.then((response) => {
				return QLIBS.authorizeAction(response);
			})
			.then(this.handleTotalesResponse)
			.catch(error => console.log(error));

	}

	handleTotalesResponse = (response) => {
		this.setState({
			totalItems: response.total,
			pages : Math.ceil(response.total / this.pageSize),
			totalesPremio: response.sumarizeItems.find(x => x.key === 'Premium').total,
			totalesPrima: response.sumarizeItems.find(x => x.key === 'Reward').total,
			totalesPremioUsd: response.sumarizeItems.find(x => x.key === 'PremiumUSD').total,
			totalesPrimaUsd: response.sumarizeItems.find(x => x.key === 'RewardUSD').total,
			loadingTotales: false
		});
	}

	handleSubmit = event => {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
		if (event)
			event.preventDefault();
		this.searchResultsError = "";
		this.showResults = false;
		let sort = this.state.sort.length === 0 ? null : this.state.sort[0];
		this.setState({ name: this.state.name, filterTabActive: -1, loadingData: true });
		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: JSON.stringify({
				next: this.pageSize,
				sort: sort,
				offset: this.page * this.pageSize,
				producerCuit: userAuth.isGestor() ? userAuth.reportTo() :  userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer(),
				producerId: this.state.searchCriteria[0].productores.map(x => parseFloat(x.id.replace('X', ''))),
				fechaDesde: this.state.searchCriteria[1].fechaDesde,
				fechaHasta: this.state.searchCriteria[1].fechaHasta,
				ramo: this.state.searchCriteria[2].ramo,
				producto: this.state.searchCriteria[2].producto,
				poliza: this.state.searchCriteria[3].poliza,
				certificado: this.state.searchCriteria[3].certificado,
				estado: this.state.searchCriteria[3].estado !== '-1' ? parseInt(this.state.searchCriteria[3].estado) : null,
				nombre: this.state.searchCriteria[4].nombre,
				apellido: this.state.searchCriteria[4].apellido,
				dni: this.state.searchCriteria[4].dni,
				gridFilters: this.state.filters
			})
		};
		var url = new URL(`${PRODUCERURL}portfolio/`);
		this.getTotales()
		return fetch(url, requestOptions)
			.then((response) => {
				return QLIBS.authorizeAction(response);
			})
			.then(this.handleResponse)
			.catch(this.handleResponse);

	};

	handleCheckedItemsChanged(checkedItems) {
		let searchCriteria = this.state.searchCriteria;
		searchCriteria[0].productores = checkedItems;
		this.setState({
			checkedItems: checkedItems,
			searchCriteria: searchCriteria
		}, () => {
			this.refreshFiltros();
		});
	}

	handleProducerIdChanged(producerId) {
		this.setState({ productorSelected: producerId });
	}

	handleProducersChanged(newProducers) {
		this.setState({ productoresData: newProducers.filter(x => x.core === 'EOS') });
	};

	handleResponse(response) {
		const { COMMON_SEARCH_FORM } = GLOBAL;
		let tableData = [];
		try {
			if (Array.isArray(response.data)) {
				this.searchResultsError = "";
				this.showResults = true;
				this.totalItems = response.total;
				//this.pages = Math.ceil(response.total / this.pageSize);
				this.productosLabel = Object.entries(response.labels.find(x => x.key === 'Productos').values).map(([value, label]) => ({ value, label }));
				this.productosLabel.unshift({ value: "", label: '-- SELECCIONAR --' });

				tableData = response.data.map(item => ({
					...item,
					premium: `$ ${truncate(item.premium, 2)}`, // PREMIO
					reward: `$ ${truncate(item.reward, 2)}`, // PRIMA
					lastStatusDate: item.lastStatusDate === null ? '-' : moment(item.lastStatusDate).format("DD/MM/YYYY"),
					issueDate: moment(item.issueDate).format("DD/MM/YYYY"),
					productCodeVt: item.producto_Codigo_VT,
					policyNumberVt: item.poliza_Numero_FRMT,
					lastBilledPeriod: moment(item.lastBilledPeriod).format("DD/MM/YYYY"),
					startDate: moment(item.startDate).format("DD/MM/YYYY"),
					endDate: (item.endDate !== undefined && item.endDate !== null && item.endDate !== '') ? moment(item.endDate).format("DD/MM/YYYY") : '-',
					core: item.source,
					facturacionTipo: item.facturacion_Tipo,
					fecha_dm: moment(item.sysDate).format("DD/MM/YYYY")
				}));


			} else {
				this.searchResultsError = COMMON_SEARCH_FORM.SERVER_ERROR;
				this.showResults = true;
			}

			this.searchTableColumns = this.createSearchTableColumns();
			this.searchTableData = {
				makeData: () => {
					return tableData
				}
			};
		} catch (error) {
			console.log(error)
		}
		finally {
			this.setState({ name: this.state.name, loadingData: false });
		}

	}

	handleExportSubmit(event) {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		event.preventDefault();
		this.setState({ loadingData: true });
		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: JSON.stringify({
				producerId: this.state.searchCriteria[0].productores.map(x => parseFloat(x.id.replace('X', ''))),
				producerCuit: userAuth.isGestor() ? userAuth.reportTo() :  userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer(),
				fechaDesde: this.state.searchCriteria[1].fechaDesde,
				fechaHasta: this.state.searchCriteria[1].fechaHasta,
				ramo: this.state.searchCriteria[2].ramo,
				producto: this.state.searchCriteria[2].producto,
				poliza: this.state.searchCriteria[3].poliza,
				certificado: this.state.searchCriteria[3].certificado,
				estado: this.state.searchCriteria[3].estado !== '-1' ? parseInt(this.state.searchCriteria[3].estado) : null,
				nombre: this.state.searchCriteria[4].nombre,
				apellido: this.state.searchCriteria[4].apellido,
				dni: this.state.searchCriteria[4].dni,
				filters: this.state.searchCriteriaText.replace(/['"]+/g, "\\'"),
				columns: JSON.stringify(this.createExcelReportColumns())
			})
		};

		fetch(`${PRODUCERURL}portfolio/export`, requestOptions)
			.then(function (resp) {
				if (resp.ok)
					return resp.blob();
			})
			.then(function (blob) {
				if (blob) {
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = "Cartera.xlsx";
					link.click();
				}
			})
			.then(() => {
				this.setState({ loadingData: false });
			})
	};

	handleSaveViewModal(newListColumns) {
		const tempTableColumns = this.createBaseTableColumns();

		this.baseTableColumns = newListColumns.map(c => {
			let col = tempTableColumns.find(f => f.name === c.name);
			col.show = c.show;
			return col;
		});

		this.searchTableColumns = this.createSearchTableColumns();
	}

	onKeyDown = (event) => {
		// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
		if (event.key === 'Enter' && this.state.searchEnabled) {
			event.preventDefault();
			event.stopPropagation();
			document.getElementById("btnSubmit").click();
		}
	}

	createSearchTableColumns() {
		const newTableColumns = [this.searchTableDownloadColumn, ...this.baseTableColumns.filter(f => f.show)];
		return newTableColumns;
	}

	createExcelReportColumns() {
		const excelReportColumns = this.baseTableColumns.filter(f => f.show).map(c => {
			return {
				Title: c.title,
				Field: c.field,
				Summarize: c.summarize,
				Format: c.format
			}
		});
		return excelReportColumns;
	}

	createBaseTableColumns() {
		const { REPORT_FORMAT } = GLOBAL;

		let newTableColumns = [];

		// Get Columns identifier        public string PolicyTypeDescription { get; set; }
		let columns = {
			"productDescription": { title: "Producto", field: "ProductDescription", show: true, width: 200 },
			"plan_Descripcion": { title: "Plan", field: "Plan_Descripcion", show: true },
			"recruiterName": { title: "Nombre", field: "RecruiterName", show: true, width: 200 },
			"premium": { title: "Premio", field: "Premium", format: REPORT_FORMAT.MONEDA, signo: '$', show: true },
			"reward": { title: "Prima", field: "Reward", format: REPORT_FORMAT.MONEDA, signo: '$', show: true },
			"currency": { title: "Moneda", field: "Currency", show: true },
			"policyNumber": { title: "Póliza", field: "PolicyNumber", show: true },
			"issueDate": { title: "Emisión", field: "IssueDate", format: REPORT_FORMAT.FECHA, show: true },
			"certificateNumber": { title: "Certificado", field: "CertificateNumber", show: true },
			"policyType": { title: "Tipo Póliza", field: "PolicyType", show: true },
			"status": { title: "Estado", field: "Status", show: true },
			"lastStatusDate": { title: "Último estado", field: "LastStatusDate", format: REPORT_FORMAT.FECHA, show: true, },
			"lastBilledPeriod": { title: "Período facturado", field: "LastBilledPeriod", format: REPORT_FORMAT.FECHA, show: true },
			"paymentMethod": { title: "Medio de Pago", field: "PaymentMethod", show: true },
			"startDate": { title: "Vigencia desde", field: "StartDate", format: REPORT_FORMAT.FECHA, show: true },
			"endDate": { title: "Vigencia hasta", field: "EndDate", format: REPORT_FORMAT.FECHA, show: true },
			"producerDescription": { title: "Productor", field: "ProducerDescription", show: true, width: 200 },
			"recruiterDni": { title: "Documento contratante", field: "RecruiterDni", show: true },
			"branchDescription": { title: "Ramo", field: "BranchDescription", show: true, width: 200 },
		};
		// Add Columns to Table
		let cols = QLIBS.getTableColumns(columns);
		cols.forEach((c) => { newTableColumns.push(c) });

		return newTableColumns;
	}

	render(...props) {
		const { TABLE_VIEW } = GLOBAL;
		const { COMMON_SEARCH_FORM } = GLOBAL;
		return (
			<>
				{this.state.showImprimeAsegurados && <ConfirmationModal
					message={"¿Desea imprimir el detalle de asegurados?"}
					status="success"
					title={"Descarga de Certificado"}
					onDismiss={() => this.onDismissImprimeAsegurados()}
					onConfirm={() => this.onConfirmImprimeAsegurados()}
					onCancel={() => this.setState({ showImprimeAsegurados: false })}
				/>}
				{this.state.showViewsModal && <ViewsModal
					listColumns={this.baseTableColumns}
					onDismiss={() => { this.setState({ showViewsModal: false }) }}
					onSave={(listColumns) => { this.handleSaveViewModal(listColumns) }}
				/>}
				<Loading visible={this.state.loadingData}></Loading>
				{this.state.showPolicyInfoModal && <PolicyInfoModal policy={this.state.selectedRow}
					onDismiss={() => { this.setState({ showPolicyInfoModal: false }) }} />}
				{this.state.showSchedulerModal && (<SchedulerModal reporte="Portfolio" api={`portfolio/export`} productores={this.state.searchCriteria[0].productores.map((item) => { return item.id.replace('X', '') })} cores={this.state.searchCriteria[0].productores.map((item) => { return item.core })} dismiss={() => { this.setState({ showSchedulerModal: false }) }} />)}
				<section className="col-12">
					<div className="row">
						<div className="col-12">
							<Breadcrumb currentPage="/cartera" />
						</div>
						<div className="col-12 mb-2">
							<LabelSection section="cartera" type="subtitulo" className="form-title" />
						</div>
						<div className="form-container container table-view col-md-12 mt-2">
							<form id="cartera_search_form_1" onSubmit={this.handleSubmit} onKeyDown={this.onKeyDown} className="register__Form" noValidate>
								<div className="row">
									<div className="col-12">
										<TabsPanel options={this.filterTabs} tabActive={this.state.filterTabActive} onChange={this.onChangeTab}>
											<div id="tab1" className="container option-1">
												{this.state.productoresData.length > 0 && (
													<ProducerSelectorEos producers={this.state.productoresData}
														ref={this.producerSelector}
														onCheckedItemsChanged={this.handleCheckedItemsChanged}
														onProducerIdChanged={this.handleProducerIdChanged}
														onProducersChanged={this.handleProducersChanged}
													/>)}

											</div>
											<div id="tab2" className="container option-2">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-6">
																<label>{COMMON_SEARCH_FORM.FECHA_EMISION.DESDE}</label>
																<DatePicker
																	id="fecha_emision_desde"
																	defaultValue={(this.state.searchCriteria[1].fechaDesde) ? this.state.searchCriteria[1].fechaDesde.toString() : ""}
																	maxLength="10"
																	noDefaultValue="true"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-6">
																<label>{COMMON_SEARCH_FORM.FECHA_EMISION.HASTA}</label>
																<DatePicker
																	id="fecha_emision_hasta"
																	defaultValue={(this.state.searchCriteria[1].fechaHasta) ? this.state.searchCriteria[1].fechaHasta.toString() : ""}
																	maxLength="10"
																	noDefaultValue="true"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div id="tab3" className="container option-3">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-6">
																{this.productosLabel &&
																	(<Combobox
																		id="producto"
																		label={COMMON_SEARCH_FORM.PRODUCTO}
																		options={this.productosLabel}
																		onChange={(v, e) => { this.handleComboChange(v, e); }}
																		defaultValue={this.state.searchCriteria[2].producto}
																	/>)
																}

															</div>
														</div>
													</div>
												</div>
											</div>
											<div id="tab4" className="container option-4">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-4">
																<Input
																	id="nro_poliza"
																	type="text"
																	text={COMMON_SEARCH_FORM.NRO_POLIZA}
																	defaultValue={this.state.searchCriteria[3].poliza}
																	classes="mb-0"
																	maxLength="50"
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-4">
																<Input
																	id="nro_certificado"
																	type="text"
																	text={COMMON_SEARCH_FORM.NRO_CERTIFICADO}
																	defaultValue={this.state.searchCriteria[3].certificado}
																	classes="mb-0"
																	maxLength="50"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															{/* <div className="col-md-4">
																<Combobox
																	id="estado"
																	label={COMMON_SEARCH_FORM.ESTADO}
																	options={FORM_DATA.STATUS.map((item) => ({ value: item.id, label: item.value }))}
																	onChange={(v, e) => { this.handleComboChange(v, e); }}
																	defaultValue={this.state.searchCriteria[3].estado}
																/>
															</div> */}
														</div>
													</div>
												</div>
											</div>
											<div id="tab5" className="container option-5">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-4">
																<Input
																	id="nombre"
																	type="text"
																	text={COMMON_SEARCH_FORM.ASEGURADO.NOMBRE}
																	defaultValue={this.state.searchCriteria[4].nombre}
																	maxLength="100"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-4">
																<Input
																	id="apellido"
																	type="text"
																	text={COMMON_SEARCH_FORM.ASEGURADO.APELLIDO}
																	defaultValue={this.state.searchCriteria[4].apellido}
																	maxLength="100"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-4">
																<Input
																	id="dni"
																	type="text"
																	text={COMMON_SEARCH_FORM.ASEGURADO.DNI}
																	defaultValue={this.state.searchCriteria[4].dni}
																	classes="mb-0"
																	maxLength="8"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</TabsPanel>
									</div>
								</div>

								{(!this.state.loadingData) && this.state.productoresData.length === 0 && (<div className="row">
									<div className="col-12 mt-3">
										<Alert
											type="error"
											icon="info"
											title={COMMON_SEARCH_FORM.PRODUCER_EMPTY}
											message=""
											noDetail={true}
										/>
									</div>
								</div>)}
								{(this.state.searchEnabled) && (
									<div className="row mt-3">
										<div className="col-12">
											<div className="search-buttons">
												<Button type="submit" id="btnSubmit" classes="to-bottom button-small search-button">
													<span className="fa fa-search"> </span> {COMMON_SEARCH_FORM.SEARCH_BUTTON}</Button>
											</div>
											<div className="search-buttons">
												<Button type="button" classes="to-bottom button-small search-button" 
												onClick={this.clearFiltros}>
													<span className="fa fa-filter"> </span> {COMMON_SEARCH_FORM.CLEAR_FILTERS}</Button>
											</div>
											{(this.state.searchEnabled && this.showResults) && (
												<div className="search-buttons">
													<Button type="submit" classes="to-bottom button-small search-button" 
													onClick={event => this.handleExportSubmit(event)}>
														<i className="fas fa-file-download mx-2" /> {TABLE_VIEW.EXPORT_TEXT}</Button>
												</div>
											)}
											<div className="search-buttons">
												<Button type="button" classes="to-bottom button-small search-button" onClick={() => { this.setState({ showViewsModal: true }) }}><span className="fa fa-table"> </span> {COMMON_SEARCH_FORM.VIEW_BUTTON}</Button>
											</div>
											<div className="search-buttons search-filters">
												<table style={{ width: '100%' }}>
													<tbody>
														<tr>
															<td><Button type="button" id="btnView" classes="to-bottom button-small search-button" onClick={() => { this.setState({ iconFilter: this.state.iconFilter === 'fa fa-eye' ? 'fa fa-eye-slash' : 'fa fa-eye', showFilters: !this.state.showFilters }) }}><span className={this.state.iconFilter}> </span> {COMMON_SEARCH_FORM.VIEW_FILTERS}</Button></td>
															{!this.state.loadingTotales && (<td>{`Se encontraron ${this.state.totalItems} registros`}</td>)}
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								)}
								{this.state.showFilters && (
									<div className="row">
										<div className={`col-12 mt-3`}>
											<Alert
												errorDesc={this.state.searchCriteriaText}
												icon="info"
												title={COMMON_SEARCH_FORM.APPLIED_FILTERS_ALERT}
												level={ErrorLevel.Information}
											/>
										</div>
									</div>
								)}
								{this.searchResultsError && (
									<div id="form-error-message" className="row my-2">
										<div className="col-12">
											<Alert
												icon="times"
												message=""
												title={this.searchResultsError}
												errorInfo={this.searchResultsError}
												level={ErrorLevel.Error}
											/>
										</div>
									</div>
								)}
								{
									(this.state.certificadoGenerado) &&
									<div id="form-error-message" className="row my-2">
										<div className="col-12">
											<Alert
												title={"Certificado generado con éxito"}
												level={ErrorLevel.Success}
												noDetail={true}
											/>
										</div>
									</div>
								}
								{(this.showResults) && (
									<div className="row my-3">

										<div className="col-12 px-3">

											{this.state.loadingTotales ? <div className='cards'>
												<div class="spinner"></div>
											</div> :
												<div className='cards'>
													<p className='totalesPremio'><b id='totales-label'>Totales</b> <b>Premio:</b> ARS {this.state.totalesPremio?.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
														{(this.state.totalesPremioUsd > 0) && (<> -  USD: {this.state.totalesPremioUsd.toLocaleString("es-AR", {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2
														})}
														</>)}</p>
													<p className='totalesPrima'>
														<b>Prima:</b> ARS {this.state.totalesPrima?.toLocaleString("es-AR", {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2
														})}
														{(this.totalesPrimaUsd > 0) && (<> -  USD: {this.state.totalesPrimaUsd.toLocaleString("es-AR", {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2
														})}
														</>)}</p>
												</div>
											}


											<TableComponent
												columns={this.searchTableColumns}
												sorted={this.getSort()}
												onSortedChange={this.applySort}
												filter={this.getFilter()}
												data={this.searchTableData.makeData()}
												manual={true}
												onFilterChange={this.applyFilter}
												page={this.page}
												pageSize={this.pageSize}
												onPageSizeChange={this.changeSize}
												pages={this.state.pages}
												changePage={this.changePage}
												expandable={true}
												subComponent={props => (<PolicyRowDetail width={(window.innerWidth - 120)} {...props} />)} {...props} />
										</div>
									</div>
								)}
							</form>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default Cartera;