import { Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import registracionStep1 from '../../Resources/Images/Tutoriales/Registracion/registracion-1.jpg';
import registracionStep2 from '../../Resources/Images/Tutoriales/Registracion/registracion-2.jpg';
import registracionStep3 from '../../Resources/Images/Tutoriales/Registracion/registracion-3.jpg';
import registracionStep4 from '../../Resources/Images/Tutoriales/Registracion/registracion-4.jpg';
import registracionStep5 from '../../Resources/Images/Tutoriales/Registracion/registracion-5.jpg';
import StepTutorial from './index-step';
function getSteps() {
    return ['Paso 1', 'Paso 2', 'Paso 3', 'Paso 4', 'Paso 5'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <StepTutorial image={registracionStep1} text={[
                <span>Ingresá a <a href='https://productores.lifeseguros.com.ar/'>https://productores.lifeseguros.com.ar/</a>
                    y presioná <b>'Registrate ahora'</b>, o accede directamente haciendo click en
                    <a href='https://productores.lifeseguros.com.ar/register'> https://productores.lifeseguros.com.ar/register</a></span>]} />
        case 1:
            return <StepTutorial image={registracionStep2} text={[
                "Completar los campos: nombre y apellidos o razón social, CUIT, E-mail (deberá ser un email válido ya que se validará su cuenta), teléfono, empresa de telefónica y contraseña con su confirmación, esta última deberá contener al menos una mayúscula, una minúscula y un número para brindarle mayor seguridad",
                <span>Presionar el tilde <b>He leido los terminos y condiciones de uso</b> posterior a leerlas</span>,
                <span>Presionar el botón <b>Registrarse</b></span>]} />
        case 2:
            return <StepTutorial image={registracionStep3} text={[
                "En caso que los datos ingresados sean válidos, se les informará que deberán validar su correo electrónico para continuar con la registración"]} />
        case 3:
            return <StepTutorial image={registracionStep4} text={[
                "Revisar su casilla de correo pasados unos minutos",
                "Abrir el e-mail de confirmación",
                <span>Presionar el botón  <b>Confirmar correo</b> </span>,
                "(En caso de no recibir el email, corroborar bandeja de correo no deseado)"]} />
        case 4:
            return <StepTutorial image={registracionStep5} text={[
                <span>Se abrirá una nueva ventana en el navegador web abriendo
                    <a href='https://productores.lifeseguros.com.ar/'> https://productores.lifeseguros.com.ar/</a>
                    con un mensaje informando que el registro finalizó correctamente</span>,
                "Se enviará un nuevo correo electronico dando la bienvenida al sistema Portal Web",
                "Felicitaciones! ya puede comenzar a utilizar el Portal de Productores"]} />;
        default:
            return '¡Tutorial completado!';
    }
}

export function Registracion({ activeStep, setSteps }) {
    const steps = getSteps();

    useEffect(() => {
        setSteps(steps);
    }, [setSteps, steps]);
    return (
        <>
        <h2>Cómo registrarse</h2>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <Typography>{getStepContent(activeStep)}</Typography>
            </div>
        </>
    );
}