import { Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";

import RechazoStep1 from '../../Resources/Images/Tutoriales/Rechazo/Rechazo-1.jpg';
import RechazoStep2 from '../../Resources/Images/Tutoriales/Rechazo/Rechazo-2.jpg';
import StepTutorial from './index-step';
function getSteps() {
    return ['Paso 1', 'Paso 2'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <StepTutorial image={RechazoStep1} text={['Seleccionar los filtros correspondientes antes de realizar la búsqueda', 'Presione el boton Buscar']} />
        case 1:
            return <StepTutorial image={RechazoStep2} text={['Al hacer click en el botón “Descargar” podrás exportar el listado de rechazos a Excel']} />
        default:
            return '¡Tutorial completado!';
    }
}

export function Rechazo({ activeStep, setSteps }) {
    const steps = getSteps();

    useEffect(() => {
        setSteps(steps);
    }, [setSteps, steps]);
    return (
        <>
            <h2>Paso a paso para utilizar la búsqueda de Rechazos</h2>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <Typography>{getStepContent(activeStep)}</Typography>
            </div>
        </>
    );
}