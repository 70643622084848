/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
	Alert,
	Breadcrumb,
	Button,
	Input,
	Loading,
	ProducerSelector,
	Table as TableComponent,
	TabsPanel,
	Combobox,
	SchedulerModal,
	ViewsModal,
	LabelSection
} from "../../Components";
import 'react-dates/initialize';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { GLOBAL } from "../../Config/global";
import "font-awesome/css/font-awesome.min.css";
import { APPCONFIG } from '../../app.config';
import moment from "moment";
import { loadProducerCode } from '../../Config/producerData'
import { truncate } from "../../Shared/Utils";
import { FORM_DATA } from "../../Config/form_data";
import userAuth from "../../Config/userauth";
import * as QLIBS from '../../qLibsHelpers';
import { DEFAULT_HEADER } from '../../Config/userToken';
class LiquidacionCtaCte extends React.Component {
	constructor(props) {
		const { COMMON_SEARCH_FORM } = GLOBAL;
		super(props);
		this.filterTabs = [
			{
				label: COMMON_SEARCH_FORM.SELECT_PRODUCERS_TAB
			},
			{
				label: COMMON_SEARCH_FORM.SELECT_DATES_TAB
			},
			{
				label: COMMON_SEARCH_FORM.SELECT_PRODUCTS_TAB
			},
			{
				label: COMMON_SEARCH_FORM.SELECT_POLICY_TAB
			}
		];
		var date = new Date(), y = date.getFullYear(), m = date.getMonth();
		this.state = {
			ramos: [],
			productos: [],
			name: '',
			checkedItems: [],
			productorSelected: 0,
			showViewsModal:false,
			enableFilterTabs: false,
			loadingData: false,
			searchEnabled: false,
			filtersSelected: false,
			filterTabActive: 0,
			searchCriteria: [
				{
					"productores": []
				},
				{
					"fechaDesde": new Date(y, m, 1),
					"fechaHasta": new Date(y, m + 1, 0),
					"vigencia": FORM_DATA.MONTHSSETTLEMENT[0].id
				},
				{
					"ramo": '',
					"producto": ''
				},
				{
					"poliza": null,
					"certificado": null
				},
				{
					"nombre": null,
					"apellido": null,
					"dni": null
				}
			],
			searchCriteriaText: "",
			productoresData: [],
			showSchedulerModal: false,
			iconFilter: 'fa fa-eye',
			showFilters: false
		};

		this.baseTableColumns = this.createBaseTableColumns();
		this.searchTableColumns = this.createSearchTableColumns();

		this.producerSelector = React.createRef();
		this.showResults = false;
		this.searchResultsError = "";
		this.downloadItem = this.downloadItem.bind(this);
		this.onChangeTab = this.onChangeTab.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.clearFiltros = this.clearFiltros.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.handleProducerIdChanged = this.handleProducerIdChanged.bind(this);
		this.handleCheckedItemsChanged = this.handleCheckedItemsChanged.bind(this);
		this.handleProducersChanged = this.handleProducersChanged.bind(this);
		this.handleSaveViewModal = this.handleSaveViewModal.bind(this);
	}

	componentDidMount() {
		/// Redirige al ebroker temporalmente
		this.props.history.push('/information');
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		var today = new Date();
		var d = new Date(today.getFullYear(), today.getMonth(), 0);
		let entityCopy = Object.assign({}, this.state.searchCriteria);
		entityCopy[1].vigencia = d.getMonth() + "-" + d.getFullYear();
		var dd = entityCopy[1].vigencia.split('-');
		entityCopy[1].fechaDesde = new Date(dd[1], dd[0], 1);
		entityCopy[1].fechaHasta = new Date(dd[1], parseInt(dd[0]) + 1, 0);
		this.setState({ searchCriteria: entityCopy }, () => { console.log(this.state.searchCriteria); });

		QLIBS.fillCombo('ramos', PRODUCERURL + 'api/datos/GetComboValues?e=Ramo_Settlement', this, GLOBAL.COMBO_EMPTY_ITEM, true);
		QLIBS.fillCombo('productos', PRODUCERURL + 'api/datos/GetComboValues?e=Producto_Settlement', this, GLOBAL.COMBO_EMPTY_ITEM, true);

		var userId = userAuth.userId();
		const requestOptions = {
			method: 'GET',
			//headers: { 'Content-Type': 'application/json' }
			headers: DEFAULT_HEADER.get()
		};

		return fetch(PRODUCERURL + "api/producer/GetProducerCodes?userId=" + userId, requestOptions)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				var uc = data;
				for (var u in uc) {
					var item = uc[u];
					for (var p in item.producerCodes) {
						var pc = item.producerCodes[p];
						pc.name = pc.name.split("|")[0];
					}
				}
				this.handleProducersChanged(loadProducerCode(uc));
			});
	}

	downloadItem = event => {
		const pdfUrl = event.row._original.pdfUrl;
		window.open(pdfUrl, "_blank");
	};

	onChangeTab = index => {
		this.setState({ name: this.state.name, filterTabActive: index, filtersSelected: (index > 0) });
	};

	handleKeyDown = event => {
		// Chek which field
		switch (event.target.id) {
			case "nro_poliza":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
					event.preventDefault();
				}
				break;

			case "nro_certificado":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
					event.preventDefault();
				}
				break;

			case "nombre":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
					event.preventDefault();
				}
				break;

			case "apellido":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
					event.preventDefault();
				}
				break;

			case "dni":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
					event.preventDefault();
				}
				break;

			default:
				// Do Nothing
				break;
		}
	}

	handleComboChange = (selectedOption, event) => {
		let inputName = event.name;
		let inputValue = selectedOption.value;
		let inputString = selectedOption.label;
		let searchCriteria = this.state.searchCriteria;

		switch (inputName) {
			case "ramo":
				searchCriteria[2].ramo = inputValue;
				break;
			case "producto":
				searchCriteria[2].producto = inputValue;
				break;
			case "mesvigencia":
				searchCriteria[1].vigencia = inputValue;
				var d = inputValue.split("-");
				searchCriteria[1].fechaDesde = new Date(d[1], d[0], 1);
				searchCriteria[1].fechaHasta = new Date(d[1], parseInt(d[0]) + 1, 0);
				break;

			default:
				break;
		}
		this.setState({
			searchCriteria: searchCriteria
		}, () => {
			console.log(searchCriteria[1]);
			this.refreshFiltros();
		});
	};

	handleChange = event => {
		let searchCriteria = this.state.searchCriteria;
		// Check which field
		switch (event.target.id) {

			case "nro_poliza":
				searchCriteria[3].poliza = event.target.value;
				break;

			case "nro_certificado":
				searchCriteria[3].certificado = event.target.value;
				break;

			case "nombre":
				searchCriteria[4].nombre = event.target.value;
				break;

			case "apellido":
				searchCriteria[4].apellido = event.target.value;
				break;

			case "dni":
				searchCriteria[4].dni = event.target.value;
				break;

			default:
				break;
		}

		this.setState({
			searchCriteria: searchCriteria
		}, () => {
			//
			this.refreshFiltros();
		});
	};

	refreshFiltros = () => {
		const { COMMON_SEARCH_FORM } = GLOBAL;
		// Populate Filtros
		let searchCriteriaText = COMMON_SEARCH_FORM.SELECTED_PRODUCERS;

		// Add productores
		const filter = {};

		// creo dinamicamente el objeto para el filtro de acuerdo al filtro seleccionado
		if (this.state.searchCriteria[0].productores.length > 0) {
			filter.ProducerId = {};
			filter.ProducerId.in = [];
			filter.Source = {};
			filter.Source.in = [];
		} else {
			delete filter.ProducerId;
			delete filter.Source;
		}

		let productores_id = Array.from(new Set(this.state.searchCriteria[0].productores.map((item) => item.id.replace('X', ''))));
		let productores_core = Array.from(new Set(this.state.searchCriteria[0].productores.map((item) => item.core)));
		let productores_text = Array.from(new Set(this.state.searchCriteria[0].productores.map((item) => item.text)));

		productores_text.forEach((item, index) => {
			searchCriteriaText += (index) ? ", " : "";
			searchCriteriaText += item;

			// agrego para la clausula in ProducerId
			let id = parseInt(productores_id[index]);
			if (id > 0)
				filter.ProducerId.in.push(id);

			let core = productores_core[index];
			if (core)
				filter.Source.in.push(core);
		});

		if (this.state.searchCriteria[2].ramo) {
			//filter.BranchCode = Number(this.state.searchCriteria[2].ramo);
			filter.BranchCode = {};
			filter.BranchCode.in = [];
			this.state.searchCriteria[2].ramo.split(';').forEach(function (value, index) {
				filter.BranchCode.in.push(parseInt(value));
			});
		} else {
			delete filter.BranchCode;
		}

		if (this.state.searchCriteria[2].producto) {
			filter.ProductCode = Number(this.state.searchCriteria[2].producto);
		} else {
			delete filter.ProductCode;
		}

		if (this.state.searchCriteria[3].poliza) {
			filter.PolicyId = parseInt(this.state.searchCriteria[3].poliza);
		} else {
			delete filter.PolicyId;
		}

		if (this.state.searchCriteria[3].certificado) {
			filter.CertificateNumber = parseInt(this.state.searchCriteria[3].certificado);
		} else {
			delete filter.CertificateNumber;
		}

		// if (this.searchCriteria[3].estado !== '-1') {
		// 	filter.Status = this.searchCriteria[3].estado;
		// }else {
		// 	delete filter.Status;
		// }

		if (this.state.searchCriteria[4].nombre || this.state.searchCriteria[4].apellido) {
			filter.RecruiterName = {};
			filter.RecruiterName.contains = "";
			filter.RecruiterName.contains += (this.state.searchCriteria[4].nombre) ? " " + this.state.searchCriteria[4].nombre : "";
			filter.RecruiterName.contains += (this.state.searchCriteria[4].apellido) ? " " + this.state.searchCriteria[4].apellido : "";
		} else {
			delete filter.RecruiterName;
		}

		if (this.state.searchCriteria[4].dni) {
			filter.RecruiterDni = {};
			filter.RecruiterDni.contains = this.state.searchCriteria[4].dni;
		} else {
			delete filter.RecruiterName;
		}

		// Add dates
		if (this.state.searchCriteria[1].fechaDesde) {
			searchCriteriaText += "<br>" + COMMON_SEARCH_FORM.DATERANGE_SEARCH_TEXT;
			searchCriteriaText += (moment(this.state.searchCriteria[1].fechaDesde)).format("DD/MM/YYYY") ? " Desde " + moment(this.state.searchCriteria[1].fechaDesde).format("DD/MM/YYYY") : "";
			searchCriteriaText += (moment(this.state.searchCriteria[1].fechaHasta).format("DD/MM/YYYY")) ? " Hasta " + moment(this.state.searchCriteria[1].fechaHasta).format("DD/MM/YYYY") : "";
		}

		if (this.state.searchCriteria[2].ramo || this.state.searchCriteria[2].producto) {
			let idramo = this.state.searchCriteria[2].ramo;
			let ramo = '';
			if (idramo) {
				ramo = this.state.ramos.filter(x => x.id === idramo)[0].value;
			}
			let idproducto = this.state.searchCriteria[2].producto;
			let producto = '';
			if (idproducto) {
				producto = this.state.productos.filter(x => x.id === idproducto)[0].value;
			}

			searchCriteriaText += "<br>" + COMMON_SEARCH_FORM.PRODUCTS_SEARCH_TEXT;
			searchCriteriaText += (ramo !== '') ? " Ramo \"" + ramo + "\"" : "";
			searchCriteriaText += ((producto !== '' && ramo !== '') ? ", " : "") + ((producto !== '') ? " Producto \"" + producto + "\"" : "");
		}

		if (this.state.searchCriteria[3].poliza || this.state.searchCriteria[3].certificado) {
			searchCriteriaText += "<br>" + COMMON_SEARCH_FORM.POLICY_SEARCH_TEXT;
			searchCriteriaText += (this.state.searchCriteria[3].poliza) ? " Póliza \"" + this.state.searchCriteria[3].poliza + "\"" : "";
			searchCriteriaText += ((this.state.searchCriteria[3].certificado && this.state.searchCriteria[3].poliza) ? ", " : "") + ((this.state.searchCriteria[3].certificado) ? " Certificado \"" + this.state.searchCriteria[3].certificado + "\"" : "");
		}

		if (this.state.searchCriteria[4].nombre || this.state.searchCriteria[4].apellido || this.state.searchCriteria[4].dni) {
			searchCriteriaText += "<br>" + COMMON_SEARCH_FORM.USER_SEARCH_TEXT;
			searchCriteriaText += (this.state.searchCriteria[4].nombre) ? " Nombre \"" + this.state.searchCriteria[4].nombre + "\"" : "";
			searchCriteriaText += ((this.state.searchCriteria[4].apellido && this.state.searchCriteria[4].nombre) ? ", " : "") + ((this.state.searchCriteria[4].apellido) ? " Apellido \"" + this.state.searchCriteria[4].apellido + "\"" : "");
			searchCriteriaText += ((this.state.searchCriteria[4].dni && (this.state.searchCriteria[4].nombre || this.state.searchCriteria[4].apellido)) ? ", " : "") + ((this.state.searchCriteria[4].dni) ? " Dni \"" + this.state.searchCriteria[4].dni + "\"" : "");
		}



		this.setState({ name: this.state.name, searchCriteriaText: searchCriteriaText });
	};

	clearFiltros = () => {
		const SEARCH_CRITERIA_DEFAUL_VALUES = [
			{
				"productores": []
			},
			{
				"vigencia": FORM_DATA.MONTHSSETTLEMENT[0].id
			},
			{
				"ramo": '',
				"producto": ''
			},
			{
				"poliza": null,
				"certificado": null,
			},
			{
				"nombre": null,
				"apellido": null,
				"dni": null
			}
		];
		document.getElementById('nro_poliza').value = '';
		document.getElementById('nro_certificado').value = '';
		document.getElementById('nombre').value = '';
		document.getElementById('apellido').value = '';
		document.getElementById('dni').value = '';

		this.showResults = false;
		this.searchResultsError = false;
		this.producerSelector.current.resetSelection();

		this.setState({
			searchCriteria: SEARCH_CRITERIA_DEFAUL_VALUES,
			checkedItems: [],
			productorSelected: 0,
			enableFilterTabs: false,
			searchEnabled: false,
			filterTabActive: 0
		}, () => {
			this.refreshFiltros();
		});
	};
	handleSubmit = event => {

		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		event.preventDefault();

		this.searchResultsError = "";
		this.showResults = false;

		this.setState({ name: this.state.name, filterTabActive: -1, loadingData: true });
		
		// Parse data to send
		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: JSON.stringify({
				producerId : this.state.searchCriteria[0].productores.map(x=>parseFloat(x.id.replace('X', ''))),
				fechaDesde: this.state.searchCriteria[1].fechaDesde,
				fechaHasta: this.state.searchCriteria[1].fechaHasta,
				ramo: this.state.searchCriteria[2].ramo,
				producto: this.state.searchCriteria[2].producto,
				poliza: this.state.searchCriteria[3].poliza,
				certificado: this.state.searchCriteria[3].certificado,
				estado: this.state.searchCriteria[3].estado !== '-1' ? parseInt(this.state.searchCriteria[3].estado) : null,
				nombre: this.state.searchCriteria[4].nombre,
				apellido: this.state.searchCriteria[4].apellido,
				dni: this.state.searchCriteria[4].dni,
			})
		};

		var url = new URL(`${PRODUCERURL}api/settlement/`);

		return fetch(url, requestOptions)
			.then((response) => {
				return QLIBS.authorizeAction(response);
			})
			.then(this.handleResponse)
			.catch(this.handleResponse);

	};

	handleCheckedItemsChanged(checkedItems) {
		let searchCriteria = this.state.searchCriteria;
		searchCriteria[0].productores = checkedItems;

		this.setState({
			checkedItems: checkedItems,
			enableFilterTabs: checkedItems.length > 0,
			searchCriteria: searchCriteria,
			searchEnabled: checkedItems.length > 0
		}, () => {
			this.refreshFiltros();
		});
	};

	handleProducerIdChanged(producerId) {
		this.setState({ productorSelected: producerId });
	};

	handleProducersChanged(newProducers) {
		this.setState({ productoresData: newProducers });
	};

	handleResponse(response) {
		const { COMMON_SEARCH_FORM } = GLOBAL;
		let tableData = [];

		if (Array.isArray(response)) {
			this.searchResultsError = "";
			this.showResults = true;
			tableData = response.map(function (item) {
				return {
					id: item.policyId,
					ramoCodigo: (item.branchCode).toString(),
					ramoDescripcion: item.branchDescription,
					contratanteDescripcion: item.recruiterName,
					productoCodigo: item.productCode,
					productoDescripcion: item.productDescription,
					poliza: (item.policyId).toString(),
					certificado: (item.certificateNumber).toString(),
					montoResultante: `$ ${truncate(item.commissionAmount, 2)}`,
					porcentaje: item.percentage,
					// prima: item.prime,
					moneda: item.currency,
					premio: `$ ${truncate(item.premiumSupplement, 2)}`,
					baseCalculo: `$ ${truncate(item.baseCalculation, 2)}`,
					productor: item.producerDescription,
					fechaImputacion: moment(item.imputationDate).format("DD/MM/YYYY"),
					fechaVigencia: moment(item.startDate).format("DD/MM/YYYY"),
					referencia: item.referenceNumber,
					documentoContratante: item.recruiterDni
				}
			});//JSON.parse(response.data);

		} else {
			this.searchResultsError = COMMON_SEARCH_FORM.SERVER_ERROR;
			this.showResults = true;
		}

		this.searchTableColumns = this.createSearchTableColumns();

		this.searchTableData = {
			makeData: () => {
				return tableData
			}
		};

		this.setState({ name: this.state.name, loadingData: false });
	};

	handleExportSubmit(event) {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		event.preventDefault();
		this.setState({ loadingData: true });

		const columns = JSON.stringify(this.createExcelReportColumns());

		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: `{"url": "${PRODUCERURL}", "filters": "${this.state.searchCriteriaText.replace(/['"]+/g, "\\'")}", "columns": "${columns.replace(/['"]/g, "\\'")}"}`
		};
		fetch(`${PRODUCERURL}settlement/export`, requestOptions)
			.then(function (resp) {
				return resp.blob();
			})
			.then(function (blob) {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = "Liquidacion.xlsx";
				link.click();
			})
			.then(() => {
				this.setState({ loadingData: false });
			});
	};

	handleSaveViewModal(newListColumns) {
		const tempTableColumns = this.createBaseTableColumns();

		this.baseTableColumns = newListColumns.map(c => {
			let col = tempTableColumns.find(f => f.name === c.name);
			col.show = c.show;
			return col;
		});

		this.searchTableColumns = this.createSearchTableColumns();
	}

	onKeyDown = (event) => {
		// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
		if (event.key === 'Enter' && this.state.searchEnabled) {
			event.preventDefault();
			event.stopPropagation();
			document.getElementById("btnSubmit").click();
		}
	}

	createSearchTableColumns() {
		const newTableColumns = [...this.baseTableColumns.filter(f => f.show)];
		return newTableColumns;
	}
	
	createExcelReportColumns() {
		const excelReportColumns = this.baseTableColumns.filter(f => f.show).map(c => {
			return {
				Title: c.title,
				Field: c.field,
				Summarize: c.summarize,
				Format: c.format
			}
		});
		return excelReportColumns;
	}

	createBaseTableColumns() {
		const { REPORT_FORMAT } = GLOBAL;

		let newTableColumns = [];

		// Get Columns identifier
		let columns = {
			"contratanteDescripcion": { title: "Contratante", field: "RecruiterName", show: true, width: 200 },
			"productoCodigo": { title: "Código producto", field: "ProductCode", show: true },
			"productoDescripcion": { title: "Producto", field: "ProductDescription", show: true, width: 200 },
			"poliza": { title: "Póliza", field: "PolicyId", show: true },
			"certificado": { title: "Certificado", field: "CertificateNumber", show: true },
			"montoResultante": { title: "Monto resultante", field: "CommissionAmount", format: REPORT_FORMAT.MONEDA, show: true, summarize: true, signo: '$' },
			"porcentaje": { title: "Porcentaje", format: REPORT_FORMAT.DECIMAL, field: "Percentage", show: true },
			"baseCalculo": { title: "Prima - Base de Cálculo", format: REPORT_FORMAT.MONEDA, field: "BaseCalculation", show: true, summarize: true, signo: '$' },
			"productor": { title: "Productor", field: "ProducerDescription", show: true, width: 200 },
			"fechaImputacion": { title: "Imputación", format: REPORT_FORMAT.FECHA, field: "ImputationDate", show: true },
			"fechaVigencia": { title: "Inicio vigencia", format: REPORT_FORMAT.FECHA, field: "StartDate", show: true },
			"moneda": { title: "Moneda", field: "Currency", show: true },
			"premio": { title: "Premio", field: "PremiumSupplement", format: REPORT_FORMAT.MONEDA, show: true, summarize: true, signo: '$' },
			"ramoCodigo": { title: "Código ramo", field: "BranchCode", show: true },
			"ramoDescripcion": { title: "Ramo", field: "BranchDescription", show: true, width: 200 },
			"referencia": { title: "Referencia", field: "ReferenceNumber", show: true },
			"documentoContratante": { title: "Documento contratante", field: "RecruiterDni", show: true },
		};

		// Add Columns to Table
		let cols = QLIBS.getTableColumns(columns);
		cols.forEach((c) => { newTableColumns.push(c) });

		return newTableColumns;
	}

	render(...props) {
		const { USER_DASHBOARD: { LOGOUT_MODAL }, CHANNEL_OPTIONS, TABLE_VIEW } = GLOBAL;
		const { LIQUIDACION_SEARCH_FORM, COMMON_SEARCH_FORM, PAGES } = GLOBAL;

		// Set Tabs disabled
		this.filterTabs[1].disabled = (!this.state.enableFilterTabs);
		this.filterTabs[2].disabled = (!this.state.enableFilterTabs);
		this.filterTabs[3].disabled = (!this.state.enableFilterTabs);
		//this.filterTabs[4].disabled = (!this.state.enableFilterTabs);//filtro por asociado

		return (
			<>
				{this.state.showViewsModal && <ViewsModal
				 listColumns={this.baseTableColumns}
				 onDismiss={() => { this.setState({ showViewsModal: false }) }}
				 onSave={(listColumns) => { this.handleSaveViewModal(listColumns) }}
				/>}
				<Loading visible={this.state.loadingData}></Loading>
				{this.state.showSchedulerModal && (<SchedulerModal reporte="Settlement" api={`settlement/export`} productores={this.state.searchCriteria[0].productores.map((item) => { return item.id.replace('X', '') })} cores={this.state.searchCriteria[0].productores.map((item) => { return item.core })} dismiss={() => { this.setState({ showSchedulerModal: false }) }} />)}
				<section className="col-12">
					<div className="row">
						<div className="col-12">
							<Breadcrumb currentPage="/liquidacion" />
						</div>
						<div className="col-12">
							<h4 className="form-title">{PAGES.TITLE.SETTLEMENT}</h4>
						</div>
						<div className="col-12 mb-2">
							<LabelSection section="liquidacion" type="subtitulo" className="form-title" />
						</div>
						<div className="form-container container table-view col-md-12 mt-2">
							<form id="liquidacion_search_form1" onSubmit={this.handleSubmit} onKeyDown={this.onKeyDown} className="register__Form" noValidate>
								<div className="row">
									<div className="col-12">
										<TabsPanel options={this.filterTabs} tabActive={this.state.filterTabActive}
											onChange={this.onChangeTab}>
											<div id="tab1" className="container option-1">
												<ProducerSelector producers={this.state.productoresData}
													ref={this.producerSelector}
													onCheckedItemsChanged={this.handleCheckedItemsChanged}
													onProducerIdChanged={this.handleProducerIdChanged}
													onProducersChanged={this.handleProducersChanged}
													selectionMultiple={false}
												/>
											</div>
											<div id="tab2" className="container option-2">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-6">
																<Combobox
																	id="mesvigencia"
																	label={COMMON_SEARCH_FORM.VIGENCIA}
																	options={FORM_DATA.MONTHSSETTLEMENT.map((item) => ({ value: item.id, label: item.value }))}
																	onChange={(v, e) => { this.handleComboChange(v, e); }}
																	defaultValue={this.state.searchCriteria[1].vigencia}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div id="tab3" className="container option-3">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-6">
																<Combobox
																	id="ramo"
																	label={COMMON_SEARCH_FORM.RAMO}
																	options={this.state.ramos.map((item) => ({ value: item.id, label: item.value }))}
																	onChange={(v, e) => { this.handleComboChange(v, e); }}
																	defaultValue={this.state.searchCriteria[2].ramo}
																/>
															</div>
															<div className="col-md-6">
																<Combobox
																	id="producto"
																	label={COMMON_SEARCH_FORM.PRODUCTO}
																	options={this.state.productos.map((item) => ({ value: item.id, label: item.value }))}
																	onChange={(v, e) => { this.handleComboChange(v, e); }}
																	defaultValue={this.state.searchCriteria[2].producto}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div id="tab4" className="container option-4">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-6">
																<Input
																	id="nro_poliza"
																	type="text"
																	text={COMMON_SEARCH_FORM.NRO_POLIZA}
																	defaultValue={this.state.searchCriteria[3].poliza}
																	classes="mb-0"
																	maxLength="50"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-6">
																<Input
																	id="nro_certificado"
																	type="text"
																	text={COMMON_SEARCH_FORM.NRO_CERTIFICADO}
																	defaultValue={this.state.searchCriteria[3].certificado}
																	classes="mb-0"
																	maxLength="50"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															{/* <div className="col-md-4">
																	<Select
																		id="estado"
																		text={COMMON_SEARCH_FORM.ESTADO}
																		defaultValue={this.searchCriteria[3].estado}
																		classes="mb-0"
																		options={ FORM_DATA.STATUS }
																		onKeyDown={this.handleKeyDown}
																		onChange={this.handleChange}
																	/>
																</div> */}
														</div>
													</div>
												</div>
											</div>
											<div id="tab5" className="container option-5">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-4">
																<Input
																	id="nombre"
																	type="text"
																	text={COMMON_SEARCH_FORM.ASEGURADO.NOMBRE}
																	defaultValue={this.state.searchCriteria[4].nombre}
																	maxLength="100"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-4">
																<Input
																	id="apellido"
																	type="text"
																	text={COMMON_SEARCH_FORM.ASEGURADO.APELLIDO}
																	defaultValue={this.state.searchCriteria[4].apellido}
																	maxLength="100"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-4">
																<Input
																	id="dni"
																	type="text"
																	text={COMMON_SEARCH_FORM.ASEGURADO.DNI}
																	defaultValue={this.state.searchCriteria[4].dni}
																	classes="mb-0"
																	maxLength="8"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</TabsPanel>
									</div>
								</div>

								{(this.state.productorSelected > 0) && (this.state.checkedItems.length <= 0) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												type="success"
												icon="info"
												title={COMMON_SEARCH_FORM.PRODUCTOR_SELECTED_ALERT}
												message=""
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected > 0) && (this.state.checkedItems.length > 0) && (!this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												type="success"
												icon="info"
												title={COMMON_SEARCH_FORM.START_SEARCH_ALERT}
												message=""
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected > 0) && (this.state.checkedItems.length > 0) && (this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												type="success"
												icon="info"
												title={COMMON_SEARCH_FORM.SET_FILTER_ALERT}
												message=""
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected <= 0) && (!this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												type="success"
												icon="info"
												title={COMMON_SEARCH_FORM.SELECT_ALERT}
												message=""
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected <= 0) && (!this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<div className="alert-modal text-center text-lg-left text-light" role="alert">
												<div className="d-md-inline">
													<LabelSection section="liquidacion" type="aviso" className="alert-modal-info alert-modal-info-label" />
												</div>
											</div>
										</div>
									</div>
								)}
								{(this.state.productorSelected <= 0) && (this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												type="error"
												icon="info"
												title={COMMON_SEARCH_FORM.SELECT_PRODUCER_ALERT}
												message=""
												noDetail={true}
											/>
										</div>
									</div>
								)}


								{(this.state.searchEnabled) && (
									<div className="row mt-3">
										<div className="col-12">
											<div className="search-buttons">
												<Button type="submit" id="btnSubmit" classes="to-bottom button-small search-button"><span className="fa fa-search"> </span> {COMMON_SEARCH_FORM.SEARCH_BUTTON}</Button>
											</div>
											<div className="search-buttons">
												<Button type="button" classes="to-bottom button-small search-button" onClick={this.clearFiltros}><span className="fa fa-filter"> </span> {COMMON_SEARCH_FORM.CLEAR_FILTERS}</Button>
											</div>
											{(this.state.searchEnabled && this.showResults && this.searchTableData.makeData().length > 0) && (
												<div className="search-buttons">
													<Button type="submit" onClick={event => this.handleExportSubmit(event)} size="small"><i className="fas fa-file-download mx-2" /> {TABLE_VIEW.EXPORT_TEXT}</Button>
												</div>
											)}
											{APPCONFIG.ENABLESCHEDULER === "true" && (
												<div className="search-buttons">
													<Button type="button" classes="to-bottom button-small search-button" onClick={() => { this.setState({ showSchedulerModal: true }) }}><span className="fa fa-clock-o"> </span> {COMMON_SEARCH_FORM.SCHEDULER_BUTTON}</Button>
												</div>
											)}
											<div className="search-buttons">
												<Button type="button" classes="to-bottom button-small search-button" onClick={() => { this.setState({ showViewsModal: true }) }}><span className="fa fa-table"> </span> {COMMON_SEARCH_FORM.VIEW_BUTTON}</Button>
											</div>
											<div className="search-buttons search-filters">
												<table style={{ width: '100%' }}>
													<tbody>
														<tr>
															<td><Button type="button" id="btnView" classes="to-bottom button-small search-button" onClick={() => { this.setState({ iconFilter: this.state.iconFilter === 'fa fa-eye' ? 'fa fa-eye-slash' : 'fa fa-eye', showFilters: !this.state.showFilters }) }}><span className={this.state.iconFilter}> </span> {COMMON_SEARCH_FORM.VIEW_FILTERS}</Button></td>
															{this.state.searchEnabled && this.showResults && this.searchTableData.makeData().length > 0 && (<td>{`Se encontraron ${this.searchTableData.makeData().length} registros`}</td>)}
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								)}
								{(this.state.productorSelected > 0 && this.state.checkedItems.length > 0 && this.state.showFilters) && (
									<div className="row">
										<div className={`col-12 mt-3`}>
											<Alert
												errorDesc={this.state.searchCriteriaText}
												errorInfo=""
												errorLine=""
												icon="info"
												message={` ${this.state.productoresData[this.state.productorSelected - 1].text}`}
												title={COMMON_SEARCH_FORM.APPLIED_FILTERS_ALERT}
												type="message"
												details={true}
											/>
										</div>
									</div>
								)}
								{this.searchResultsError && (
									<div id="form-error-message" className="row my-2">
										<div className="col-12">
											<Alert
												icon="times"
												message=""
												title={this.searchResultsError}
												errorInfo={this.searchResultsError}
												type="error"
											/>
										</div>
									</div>
								)}
								{(this.state.searchEnabled && this.showResults) && (
									<div className="row my-3">
										<div className="col-12 px-3">
											<TableComponent columns={this.searchTableColumns} data={this.searchTableData.makeData()} {...props} />
										</div>
									</div>
								)}
							</form>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default LiquidacionCtaCte;