import React from 'react';
import userAuth from "../../Config/userauth";
import { APPCONFIG } from '../../app.config';
class HomeAccountManagerInfo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ejecutivoInfo: {
        avatar: '',
        name: ''
      },
      error: null
    };

    this.handleResponseOk = this.handleResponseOk.bind(this);
    this.handleResponseError = this.handleResponseError.bind(this);
  }

  componentDidMount() {
    const userId = userAuth.isGestor() ? userAuth.reportTo() : userAuth.cuitProducer();
    this.loadEjecutivoInfo(userId);
  }

  async loadEjecutivoInfo(userId) {
    const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    this.setState({ loading: true })
    const response = await fetch(PRODUCERURL + "api/producer/GetAccountManagerByCuit?cuit=" + userId, requestOptions);
    if (response.ok)
      this.handleResponseOk(response);
    else
      this.handleResponseError(response);

  }


  handleResponseOk(response) {
    response.json()
      .then((data) => {
        if (data && data.success) {
          this.setState({ ejecutivoInfo: data });
        }
        else {
          this.setState({ error: "No se pudo procesar la información" });
        }
        this.setState({ loading: false })
      });
  }

  handleResponseError(response) {
    response.text()
      .then((error) => {
        this.setState({ error: error });
      });
  }


  render() {

    return (
      <div className="account-manager-info-container">

        <div className="account-manager-info-form-header">
          <span>Mi ejecutivo de cuenta</span>
        </div>
        {
          <div className="row">

            {
              this.state.loading ? 
              <div className="account-manager-campo-imagen">
                <div class="spinner"></div>
              </div> 
              :
                (this.state.ejecutivoInfo.avatar) ?
                  <img className="account-manager-campo-imagen" alt="foto avatar" src={this.state.ejecutivoInfo.avatar} />
                  : <span className="account-manager-campo-imagen">
                    <i class="fas fa-image"></i>
                  </span>
            }


            <div className="col-12" hidden={this.state.loading}>
              <div className="row pb-3 pt-4">
                <div className="col-1 account-manager-campo-titulo"><i className="fa fa-user"></i></div>
                <div className="account-manager-campo-valor">{this.state.ejecutivoInfo.name?? 'Sin datos'}</div>
              </div>
                <div className="row pb-3">
                  <div className="col-1 account-manager-campo-titulo"><i className="fa fa-phone"></i></div>
                  <div className="account-manager-campo-valor">{this.state.ejecutivoInfo.telephone ? <a target='_blank' rel="noopener noreferrer" href={`https://wa.me/${this.state.ejecutivoInfo.telephone?.replace('+', '').replace('-', '').replace(/ /g, '')}`}>{this.state.ejecutivoInfo.telephone}</a>:'Sin datos'}</div>
                </div>

                <div className="row pb-3">
                  <div className="col-1 account-manager-campo-titulo"><i className="fa fa-envelope"></i></div>
                  <div className="account-manager-campo-valor">{this.state.ejecutivoInfo.email ?? 'Sin datos'}</div>
                </div>
            </div>
          </div>
        }
        {this.state && this.state.error && !this.state.ejecutivoInfo &&
          <div>
            <div className="row pb-3 pt-4">
              <div className="col-1"></div>
              <div className="col-10 account-manager-campo-error">{this.state.error}</div>
              <div className="col-1"></div>
            </div>
          </div>
        }
      </div>
    );

  }

};

export default HomeAccountManagerInfo;
