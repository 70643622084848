import TreeView from 'devextreme-react/tree-view';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Person from '@material-ui/icons/Person';
import React from "react";
import Radiobox from '../Radiobox';

export default class ProducerSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			productorSelected: 0,
			checkedItems: [],
		};
		this.currentIndex = null;
		this.productoresData = [];
		this.productoresChildsSelected = [];
	}

	processItem = item => {
		let itemIndex = -1;
		const checkedItems = this.state.checkedItems;

		checkedItems.forEach((currentItem, index) => {
			if (currentItem.id === item.id) {
				itemIndex = index;
				return false;
			}
		});

		if (item.selected) {
			checkedItems.push(item);
		} else if (!item.selected) {
			checkedItems.splice(itemIndex, 1);
		}

		return checkedItems;
	};

	resetSelection = () => {
		this.setState({
			productorSelected: 0,
			checkedItems: []
		}, () => {
			Array.from(document.getElementsByClassName('chkProductor')).forEach(function (f) { f.disabled = true; f.checked = false; });
			this.productoresData.forEach(item => {
				item.items.forEach(Internalitem => {
					Internalitem.selected = false;
					Internalitem.items.forEach(Internalitem2 => {
						Internalitem2.selected = false;
					});
				});
			});
			this.currentIndex = null;
		});
	};

	setProductorDefault = code => {
		
		let index = null;
		this.productoresData.forEach((item, i) => {
			if (item.id === code) {
				index = (i + 1);
				return false;
			}
		});

		if (index !== null) {
			let checkbox = document.getElementsByClassName(`chkProductorCode_${code}`)[0];
	
			//se deshabilitan todos
			Array.from(document.getElementsByClassName('chkProductor')).forEach(function (f) { f.disabled = true; f.checked = false; });
			//se habilita este en particular
			checkbox.disabled = false;
			checkbox.click();
	
			if (this.currentIndex !== index) {
				// Deselect every child pre-selected
				this.productoresData.forEach(item => {
					item.items.forEach(Internalitem => {
						Internalitem.selected = (Internalitem.id === `${code}X`);
						Internalitem.items.forEach(Internalitem2 => {
							Internalitem2.selected = false;
						});
					});
				});
				this.currentIndex = index;
			}
	
			this.setProductorSelected(index);
			this.setState({ productorSelected: index },
			() => {
				this.props.onCheckedItemsChanged(this.state.checkedItems, true);
			});
		}
	}

	setProductorSelected = index => {
		let root = [];
		/// Busca hijos seleccionados
		let items = this.productoresData[index - 1].items.length ? this.productoresData[index - 1].items.filter(function (f) { return f.selected }) : Array(0);
		items.filter(function (f) { return f.selected }).forEach(function (f) {
			root.push({
				category: "",
				id: f.id,
				itemData: {},
				selected: true,
				text: f.text,
				core: f.core
			})
		});
		this.setState({ productorSelected: index, checkedItems: root }, () => {
			this.props.onCheckedItemsChanged(this.state.checkedItems);
			this.props.onProducerIdChanged(index);
		});
	}


	onProducersChange = (newProducers) => {
		this.productoresData = newProducers;
		this.resetSelection();
	};

	setProductoresSelectedAll = e => {
		let checkAll = e.value;
		if (checkAll !== undefined) {
			e.component.getNodes().forEach((node) => this.setProductoresSelected({node: node}));
		}
	}

	setProductoresSelected = e => {
		// 
		const nodeItem = e.node;
		let checkedItemsNew = [];

		if (nodeItem.items.length) {
			nodeItem.items.forEach(item => {
				checkedItemsNew = this.processItem({
					id: item.key,
					text: item.text,
					core: item.itemData.core,
					itemData: item.itemData,
					selected: item.selected,
					category: ((item.parent) ? item.parent.text : "")
				});
			});
		}

		checkedItemsNew = checkedItemsNew.concat(this.processItem({
			id: nodeItem.key,
			text: nodeItem.text,
			core: nodeItem.itemData.core,
			itemData: nodeItem.itemData,
			selected: nodeItem.selected,
			category: ((nodeItem.parent) ? nodeItem.parent.text : "")
		}));

		// 
		checkedItemsNew = checkedItemsNew.filter((v, i, a) => a.findIndex(t => (t.id === v.id && t.name === v.name)) === i)
		this.setState({ checkedItems: checkedItemsNew }, () => { this.props.onCheckedItemsChanged(this.state.checkedItems); });
	};

	enabledCheckbox = (e, index) => {
		let checkbox = e.target.closest('.row.productores-selector').querySelector('.chkProductor');

		/// Solo se ejecuta en el click sobre el checkbox
		if (e.target.control && e.target.control.type === "radio" && checkbox.checked === false) {
			Array.from(document.getElementsByClassName('chkProductor')).forEach(function (f) { f.disabled = true; f.checked = false; });
			document.getElementById(index).disabled = false;
			checkbox.click();

			if (this.currentIndex !== index) {
				// Deselect every child pre-selected
				this.productoresData.forEach(item => {
					item.items.forEach(Internalitem => {
						Internalitem.selected = false;
						Internalitem.items.forEach(Internalitem2 => {
							Internalitem2.selected = false;
						});
					});
				});
				this.currentIndex = index;
			}

			this.setProductorSelected(index);
			this.setState({
				productorSelected: index
			}, () => { this.props.onCheckedItemsChanged(this.state.checkedItems); });

			e.preventDefault();
			e.stopPropagation();
		}
	};

	renderTreeViewItem = value => {
		return (<div title={`${value.text.trim()} - CORE: ${value.core}`}>{value.text}</div>);
	};

	getProductorFromText(text) {
		//quita el codigo de productor
		return text.substring(text.indexOf(")")+1).trim();
	}
	getDivisionFromCore(core) {
		if (core === "VT")
			return "VIDA Y GENERALES";
		else if (core === "RVT")
			return "RETIRO";
		else
			return core;
	}

	render() {
		this.productoresData = this.props.producers.filter(x=>x.core!=='EOS');
		return (
			<>
				<div className="row mr-0 ml-0">
					<div className="col-6 pt-3">
						<span>División</span>
					</div>
					<div className="col-6 pt-3">
					{(this.state.productorSelected > 0 && this.productoresData.filter((menu, index) => menu.items && menu.items.length > 0 && index + 1 === this.state.productorSelected).length > 0) &&
						<span>Productor</span>
					}
					</div>
				</div>
				<div className="row mr-0 ml-0">
					<div className="col-6 pt-2">
						<List component="nav" aria-label="main mailbox folders">
							{this.productoresData.map((menu, index) => (
								<div key={index} className="row productores-selector">
									<ListItem
										button
										onClick={(e) => this.enabledCheckbox(e, index + 1)} >
										<div className="customquares">
											<Radiobox id={index + 1} value={index + 1} groupName="DivisionSelection" className={`chkProductor chkProductorCode_${menu.id}`} disabled={true} onChange={(e) => this.setProductorSelected(index + 1)} />
											{/* <Checkbox id={index + 1} className="chkProductor" disabled={true} onChange={(e) => this.setProductorSelected(index + 1)} /> */}
										</div>
										<ListItemIcon>
											<Person />
										</ListItemIcon>
										<ListItemText title={`${menu.text} - CORE: ${menu.core}`} primary={`${this.getProductorFromText(menu.text)} - ${this.getDivisionFromCore(menu.core)}`} />
									</ListItem>
									<Divider />
								</div>
							))}
						</List>
					</div>
					{(this.props.hideChilds === false) && (
						<div className="col-6 pt-2 mt-2">
							{(this.state.productorSelected > 0) &&
								this.productoresData.map((menu, index) => (
									(menu.items && menu.items.length > 0 && index + 1 === this.state.productorSelected) && (
										<div key={index} className={`${(menu.items.length ? "" : "mr-0 ml-0")} row productores-tree`}>
											<TreeView
												id={`selection-treeview_${index}`}
												items={menu.items}
												width="100%"
												selectNodesRecursive={true}
												showCheckBoxesMode={(menu.items.length > 1) ? "selectAll" : "normal"}
												selectAllText="Todos"
												selectionMode={(this.props.selectionMultiple) ? "multiple" : "single"}
												onItemSelectionChanged={this.setProductoresSelected}
												onSelectAllValueChanged={this.setProductoresSelectedAll}
												itemRender={this.renderTreeViewItem}
											/>
										</div>
									)
								))
							}
						</div>
					)}
				</div>
			</>
		);
	}
}

ProducerSelector.defaultProps = {
	producers: [],
	hideChilds: false,
	selectionMultiple: true
};