import TreeView from 'devextreme-react/tree-view';
import React from "react";

export default class ProducerSelectorEos extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checkedItems: [],
		};
		this.currentIndex = null;
		this.productoresData = [];
		this.productoresChildsSelected = [];
	}

	processItem = item => {
		let itemIndex = -1;
		const checkedItems = this.state.checkedItems;

		checkedItems.forEach((currentItem, index) => {
			if (currentItem.id === item.id) {
				itemIndex = index;
				return false;
			}
		});

		if (item.selected) {
			checkedItems.push(item);
		} else if (!item.selected) {
			checkedItems.splice(itemIndex, 1);
		}

		return checkedItems;
	};

	setProductoresSelectedAll = e => {
		let checkAll = e.value;
		if (checkAll !== undefined) {
			e.component.getNodes().forEach((node) => this.setProductoresSelected({node: node}));
		}
	}

	setProductoresSelected = e => {
		// 
		const nodeItem = e.node;
		let checkedItemsNew = [];

		if (nodeItem.items.length) {
			nodeItem.items.forEach(item => {
				checkedItemsNew = this.processItem({
					id: item.key,
					text: item.text,
					core: item.itemData.core,
					itemData: item.itemData,
					selected: item.selected,
					category: ((item.parent) ? item.parent.text : "")
				});
			});
		}

		checkedItemsNew = checkedItemsNew.concat(this.processItem({
			id: nodeItem.key,
			text: nodeItem.text,
			core: nodeItem.itemData.core,
			itemData: nodeItem.itemData,
			selected: nodeItem.selected,
			category: ((nodeItem.parent) ? nodeItem.parent.text : "")
		}));

		// 
		checkedItemsNew = checkedItemsNew.filter((v, i, a) => a.findIndex(t => (t.id === v.id && t.name === v.name)) === i)
		this.setState({ checkedItems: checkedItemsNew }, () => { this.props.onCheckedItemsChanged(this.state.checkedItems); });
	};

	render() {
		this.productoresData = this.props.producers
		return (
			<>
				<div className="row mr-0 ml-0">
					<div className="col-6 pt-3">
						<span>Productores</span>
					</div>
				</div>
				<div className="row mr-0 ml-0">
					{(
						<div className="col-6 pt-2 mt-2">
							{
								this.productoresData.map((menu, index) => (
									(
										<div key={index} className={`${(menu.items.length ? "" : "mr-0 ml-0")} row productores-tree`}>
											<TreeView
												id={`selection-treeview_${index}`}
												items={menu.items}
												width="100%"
												selectNodesRecursive={true}
												showCheckBoxesMode={(menu.items.length > 1) ? "selectAll" : "normal"}
												selectAllText="Todos"
												selectionMode={(this.props.selectionMultiple) ? "multiple" : "single"}
												onItemSelectionChanged={this.setProductoresSelected}
												onSelectAllValueChanged={this.setProductoresSelectedAll}
												itemRender={this.renderTreeViewItem}
											/>
										</div>
									)
								))
							}
						</div>
					)}
				</div>
			</>
		);
	}
}

ProducerSelectorEos.defaultProps = {
	producers: [],
	hideChilds: false,
	selectionMultiple: true
};