import {
  Home,
  User,
  UserDashboardInfo,
  UserDashboardNotifications,
  Cartera,
  LibreDeuda,
  CargaNominas,
  RubricaDigital,
  EnvioFacturas,
  Deudores,
  AdminUsers,
  AdminNotificaciones,
  HistorialNotificaciones,
  AdminComunicaciones,
  AdminLeyendas,
  HistorialComunicaciones,
  SimulateProducer,
  Auditoria,
  Estadisticas,
  Consultas,
  CertificadosRetencion,
  Simuladores
} from "../Containers";
import LiquidacionCtaCte from "../Containers/LiquidacionCtaCte";
import RechazoMotivo from "../Containers/RechazoMotivo";
import Novedades from '../Containers/Novedades';
import Information from "../Containers/Information";
import Repositorio from "../Containers/Repositorio";
import Delegado from "../Containers/User/Delegado";
import Avanzadas from "../Containers/Avanzadas";
import ComisionesPendientes from "../Containers/ComisionesPendientes";
import PagosYLiquidaciones from "../Containers/PagosYLiquidaciones";
import ConceptosFacturar from "../Containers/ConceptosFacturar";
import { Tutoriales } from "../Containers/Tutoriales";


export default [
  {
    path: "/user",
    component: User,
    breadcrumb: "User"
  },
  {
    path: "/cartera",
    component: Cartera,
    breadcrumb: "Búsqueda de cartera"
  },
  {
    path: "/libredeuda",
    component: LibreDeuda,
    breadcrumb: "Libre deuda"
  },
  {
    path: "/comisiones-pendientes",
    component: ComisionesPendientes,
    breadcrumb: "Comisiones pendientes"
  },
  {
    path: "/pagos-liquidaciones",
    component: PagosYLiquidaciones,
    breadcrumb: "Pagos y Liquidaciones"
  },
  {
    path: "/conceptos-a-facturar",
    component: ConceptosFacturar,
    breadcrumb: "Conceptos a Facturar"
  },
  {
    path: "/envionominas",
    component: CargaNominas,
    breadcrumb: "Envío de nóminas"
  },
  {
    path: "/rubricadigital",
    component: RubricaDigital,
    breadcrumb: "Rubrica digital"
  },
  {
    path: "/home",
    component: Home,
    breadcrumb: "Home"
  },
  {
    path: "/liquidacion",
    component: LiquidacionCtaCte,
    breadcrumb: "Liquidacion según movimiento CtaCte"
  },
  {
    path: "/simulator",
    component: Simuladores,
    breadcrumb: "Simuladores"
  },
  {
    path: "/information",
    component: Information,
    breadcrumb: "Liquidacion según movimiento CtaCte"
  },
  {
    path: "/rechazos",
    component: RechazoMotivo,
    breadcrumb: "Rechazos por motivo"
  },
  {
    path: "/user-dashboard-info",
    component: UserDashboardInfo,
    breadcrumb: "User Dashboard"
  },
  {
    path: "/user-dashboard-notifications",
    component: UserDashboardNotifications,
    breadcrumb: "User Dashboard"
  },
  {
    path: "/enviofacturas",
    component: EnvioFacturas,
    breadcrumb: "Envío de Facturas"
  },
  {
    path: "/deudores-policyExpired",
    component: Deudores,
    breadcrumb: "Deudores por premio"
  },
  {
    path: "/deudores",
    component: Deudores,
    breadcrumb: "Deudores por premio"
  },
  {
    path: "/admin-users",
    component: AdminUsers,
    breadcrumb: "Administrar usuarios",
    exact: true
  },
  {
    path: "/delegado",
    component: Delegado,
    breadcrumb: "Administrar delegados",
    exact: true
  },
  {
    path: "/simulate-producer",
    component: SimulateProducer,
    breadcrumb: "Simular productor",
    menu_producer: false,
    exact: true
  },
  {
    path: "/adminnotificaciones",
    component: AdminNotificaciones,
    breadcrumb: "Notificaciones",
    exact: true
  },
  {
    path: "/historialnotificaciones",
    component: HistorialNotificaciones,
    breadcrumb: "Notificaciones",
    exact: true
  },
  {
    path: "/admincomunicaciones",
    component: AdminComunicaciones,
    breadcrumb: "Comunicaciones",
    exact: true
  },
  {
    path: "/adminleyendas",
    component: AdminLeyendas,
    breadcrumb: "Leyendas en las planillas",
    exact: true
  },
  {
    path: "/historialcomunicaciones",
    component: HistorialComunicaciones,
    breadcrumb: "Comunicaciones",
    exact: true
  },
  {
    path: "/auditoria-productor",
    component: Auditoria,
    breadcrumb: "Auditoria",
    exact: true
  },
  {
    path: "/estadisticas",
    component: Estadisticas,
    breadcrumb: "Estadísticas",
    exact: true
  },
  {
    path: "/news",
    component: Novedades,
    breadcrumb: "Marketing",
    exact: true
  },
  {
    path: "/repositorio",
    component: Repositorio,
    breadcrumb: "Repositorio",
    exact: true
  },
  {
    path: "/consulta",
    component: Consultas,
    breadcrumb: "Consultas"
  },
  {
    path: "/avanzadas",
    component: Avanzadas,
    breadcrumb: "Avanzadas",
    menu_producer : false
  },
  {
    path: "/certificadosRetencion",
    component: CertificadosRetencion,
    breadcrumb: "Certificados de retención"
  }, {
    path: "/tutoriales",
    component: Tutoriales,
    breadcrumb: "Tutoriales"
  },
];