const APPCONFIG = {
    ENDPOINTS: {
        SECURITYURL: process.env.REACT_APP_SECURITYURL,
        PRODUCERURL: process.env.REACT_APP_PRODUCERURL,
    },
    FOOTER: {
        ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
        VERSION: 'V 2.0.2',
        PORTAL: 'Portal de Productores',
        BUTTON_TEXT: 'Soporte técnico',
    },
    URLS: {
        CANALESLINK: process.env.REACT_APP_CANALESLINK,
        EBROKERLINK: process.env.REACT_APP_EBROKERLINK,
        // JIRASUPPORT1: 'https://soporte.grupost.com.ar/s/d41d8cd98f00b204e9800998ecf8427e-CDN/-adif04/802003/b8e270f030cf4a918f31579d47ad7325/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=507202dd',
        // JIRASUPPORT2: 'https://soporte.grupost.com.ar/s/dfbeec73fe491620a6c3bac34a6ac5fa-T/-adif04/802003/b8e270f030cf4a918f31579d47ad7325/3.0.7/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=507202dd',
    },
    ENABLESCHEDULER: process.env.REACT_APP_ENABLESCHEDULER
}
export { APPCONFIG };