import { Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import StepTutorial from "./index-step";
import CarteraStep1 from '../../Resources/Images/Tutoriales/Cartera/Cartera-1.jpg';
import CarteraStep2 from '../../Resources/Images/Tutoriales/Cartera/Cartera-2.jpg';
import IconIndent from '../../Resources/Images/Tutoriales/Cartera/indent.jpg';
import IconDownload from '../../Resources/Images/Tutoriales/Cartera/download.jpg';
function getSteps() {
    return ['Paso 1', 'Paso 2'];
}
function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <StepTutorial image={CarteraStep1} text={['Seleccionar los filtros correspondientes antes de realizar la búsqueda', 'Presione el boton Buscar']} />
        case 1:
            return <StepTutorial image={CarteraStep2}
                text={[
                    <span>Al hacer click en <img src={IconIndent} alt="..." /> podrás abrir la póliza y navegarla en sus distintas secciones</span>,
                    <span>Al hacer click en <img src={IconDownload} alt="..." /> podrás descargar la póliza / certificado de cobertura</span>,
                    'Al hacer click en el botón “Descargar” podrás exportar tu listado de pólizas a Excel'
                ]} />
        default:
            return '';
    }
}
export function Cartera({ activeStep, setSteps }) {
    const steps = getSteps();

    useEffect(() => {
        setSteps(steps);
    }, [setSteps, steps]);
    return (
        <>
        <h2>Cartera</h2>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <Typography>{getStepContent(activeStep)}</Typography>
            </div>
        </>
    );
}