import { Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";

import CertifRetencionesStep1 from '../../Resources/Images/Tutoriales/CertifRetenciones/CertifRetenciones-1.jpg';
import CertifRetencionesStep2 from '../../Resources/Images/Tutoriales/CertifRetenciones/CertifRetenciones-2.jpg';
import StepTutorial from './index-step';
function getSteps() {
    return ['Paso 1','Paso 2'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <StepTutorial image={CertifRetencionesStep1} text={[
                'Hacer clic en “Ingresar” para ser redirigido al portal de ebroker para acceder a la información solicitada.',
            ]} />
        case 1:
            return <StepTutorial image={CertifRetencionesStep2} text={[' Accede al portal de ebroker volviendo a ingresar tus credenciales']} />
        default:
            return '¡Tutorial completado!';
    }
}

export function Certificados({ activeStep, setSteps }) {
    const steps = getSteps();

    useEffect(() => {
        setSteps(steps);
    }, [setSteps, steps]);
    return (
        <>
            <h2>Certificados de retención</h2>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <Typography>{getStepContent(activeStep)}</Typography>
            </div>
        </>
    );
}