/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { func } from 'prop-types';

const Actions = ({ onDownload, icon, disabled }) => {
  return (
    disabled &&(
      <i className={icon} />
    )||
    <div className="actions-wrapper">
      <i className={icon} onClick={onDownload} role="button" tabIndex="0" style={{ fontSize: '20px'}} />
    </div>
  )
}

Actions.propTypes = {
  onDownload: func.isRequired
};

export default Actions;
