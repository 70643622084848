/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Alert, Input, Select, Button, ProfileBadge, SimulatorWarning } from "..";
import InputCheck from '../../Resources/Images/Logos/input-check.png';
import InputError from '../../Resources/Images/Logos/input-error.png';
import { GLOBAL } from "../../Config/global";
import { FORM_DATA } from "../../Config/form_data";
import { FORM_ERRORS } from "../../Config/form_errors";
import { APPCONFIG } from '../../app.config';
import userAuth from "../../Config/userauth";
import Avatar from 'react-avatar-edit'
import { DEFAULT_HEADER } from '../../Config/userToken';
import * as QLIBS from '../../qLibsHelpers';
import { checkEmail,checkPassword,ValidarCuit } from '../../Shared/Utils/form_validation';
import { ErrorLevel } from '../Alert/errorLevel';

class UserConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      alert: "",
      userInfo: null,
      loadingForm: false,
      originalImage: null,
      cropsCounter: 0,
      showWarningSimulator: false,
      listAccountManager: null
    };

    this.nombre = "";
    this.apellido = "";
    this.cuit = "";
    this.email = "";
    this.telefono = "";
    this.compania = "";
    this.password = "";
    this.newpassword = "";
    this.confirmacion = "";
    this.ejecutivo = "";

    this.simulateMode = false;
    this.readingMode = true;
    this.formError = false;
    this.formSuccess = false;
    this.formErrorMessage = "";
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResponse = this.handleResponse.bind(this);

    this.onCrop = this.onCrop.bind(this);
    this.onImageLoad = this.onImageLoad.bind(this);
  }

  onImageLoad(image) {
    // console.log(image);
    // this.setState({originalImage : image});
  }

  onCrop(preview) {
    let userInf = this.state.userInfo;
    userInf.avatar = preview;

    this.setState({ userInfo: userInf, cropsCounter: this.state.cropsCounter + 1 });
  }

  componentDidMount() {
    this.simulateMode = userAuth.isUserSimulator();
    const userId = userAuth.userId();
    this.loadUserInfo(userId);
  }

  loadUserInfo(userId) {
    const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		const requestOptions2 = {
			method: 'GET',
			//headers: { 'Content-Type': 'application/json' }
			headers: DEFAULT_HEADER.get()
		};

    this.setState({ loadingForm: true }, () => {
      this.props.onUserConfigLoadToggle(this.state.loadingForm);
    });

    Promise.all([
      fetch(PRODUCERURL + "api/producer/GetProducerById?userId=" + userId, requestOptions2),
      fetch(PRODUCERURL + "api/producer/GetAccountManagers", requestOptions2)
    ]).then(function([responseA1, responseA2]) {

      return Promise.all([
        responseA1.json(),
        responseA2.json()
      ]);

    }).then(function([responseB1, responseB2]) {

      if (responseB2 && responseB1 && responseB1.success && responseB1.user) {
        const { COMPONENTS_FORM } = GLOBAL;
        const accountManagers = [
          {id: 0, value: COMPONENTS_FORM.SELECT_INPUT_OPTIONAL},
          ...responseB2.map(item => { return {id: item.id, value: item.name + " " + item.lastName} })
        ];
        responseB1.originalImage = responseB1.avatar;
        this.setState({ userInfo: responseB1, originalImage: responseB1.avatar, listAccountManager: accountManagers });
      }

    }.bind(this)).then(() => {

      this.setState({ loadingForm: false }, () => {
        this.props.onUserConfigLoadToggle(this.state.loadingForm);
      });

    });

  }

  setReadingMode = () => {
    this.readingMode = !this.readingMode;
    this.setState({ name: this.state.name });
  };

  handleKeyDown = event => {
    // Chek which field
    switch (event.target.id) {
      case "nombre":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
          event.preventDefault();
        }
        break;

      case "apellido":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
          event.preventDefault();
        }
        break;

      case "email":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 65 || event.keyCode > 90) && (event.keyCode < 96 || event.keyCode > 105) &&
          (event.keyCode < 192 || event.keyCode > 252) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 190 && event.keyCode !== 173) {
          event.preventDefault();
        }
        break;

      case "telefono":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
          event.preventDefault();
        }
        break;

      case "password":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 65 || event.keyCode > 90) && (event.keyCode < 96 || event.keyCode > 105)) {
          event.preventDefault();
        }
        break;

      case "newpassword":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 65 || event.keyCode > 90) && (event.keyCode < 96 || event.keyCode > 105)) {
          event.preventDefault();
        }
        break;

      case "confirmacion":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 65 || event.keyCode > 90) && (event.keyCode < 96 || event.keyCode > 105)) {
          event.preventDefault();
        }
        break;

      default:
        // Do Nothing
        break;
    }
  };

  handleChange = event => {

    const { COMMON_ERRORS } = FORM_ERRORS;

    // Chek which field
    switch (event.target.id) {
      case "nombre":
        this.nombreErrorIcon = "";
        this.nombreErrorBorderColor = "";
        break;

      case "apellido":
        this.apellidoErrorIcon = "";
        this.apellidoErrorBorderColor = "";
        break;

      case "cuit":
        // Remove "-"
        this.cuit = (event.target.value) ? event.target.value.replace(/-/g, "") : "";

        // Validate data
        this.cuitValid = ValidarCuit(this.cuit);
        if (this.cuitValid) {
          this.cuitError = "";
          this.cuitErrorIcon = (this.cuitError) ? InputError : InputCheck;
          this.cuitErrorBorderColor = (this.cuitError) ? "error" : "success";
        } else {
          this.cuitErrorIcon = "";
          this.cuitErrorBorderColor = "";
        }
        break;

      case "email":
        if (checkEmail(event.target.value)) {
          this.emailError = "";
          this.emailErrorIcon = (this.emailError) ? InputError : InputCheck;
          this.emailErrorBorderColor = (this.emailError) ? "error" : "success";
        } else {
          this.emailErrorIcon = "";
          this.emailErrorBorderColor = "";
        }
        break;

      case "telefono":
        if (!isNaN(event.target.value) && event.target.value.length >= 8) {
          this.telefonoErrorIcon = "";
          this.telefonoErrorBorderColor = "";
        }
        break;

      case "compania":
        if (event.index > 0) {
          this.companiaError = "";
        } else {
          this.companiaError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION;
        }
        this.companiaErrorIcon = (this.companiaError) ? InputError : InputCheck;
        this.companiaErrorBorderColor = (this.companiaError) ? "error" : "success";
        break;

      case "password":
        this.passwordErrorIcon = "";
        this.passwordErrorBorderColor = "";
        break;

      case "newpassword":
        this.newpasswordErrorIcon = "";
        this.newpasswordErrorBorderColor = "";
        break;

      case "confirmacion":
        this.confirmacionErrorIcon = "";
        this.confirmacionErrorBorderColor = "";
        break;

      default:
        // Do Nothing
        break;
    }

    this.setState({ name: this.state.name });
  };

  handleSubmit = event => {

    const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
    const { COMMON_ERRORS } = FORM_ERRORS;
    event.preventDefault();
    new FormData(event.target);
    let company = event.target.compania.value;

    this.nombre = event.target.nombre.value.toUpperCase();
    this.apellido = event.target.apellido.value.toUpperCase();
    this.cuit = event.target.cuit.value;
    this.email = event.target.email.value;
    this.telefono = event.target.telefono.value;
    this.compania = company === "0" ? this.state.userInfo.telephoneCompany : company;
    this.password = event.target.password.value;
    this.newpassword = event.target.newpassword.value;
    this.confirmacion = event.target.confirmacion.value;
    this.formError = false;

    this.nombreError = "";
    this.apellidoError = "";
    this.cuitError = "";
    this.emailError = "";
    this.telefonoError = "";
    this.companiaError = "";
    this.passwordError = "";
    this.newpasswordError = "";
    this.confirmacionError = "";
    this.ejecutivoError = "";

    this.nombreErrorIcon = "";
    this.apellidoErrorIcon = "";
    this.cuitErrorIcon = "";
    this.emailErrorIcon = "";
    this.telefonoErrorIcon = "";
    this.companiaErrorIcon = "";
    this.passwordErrorIcon = "";
    this.newpasswordErrorIcon = "";
    this.confirmacionErrorIcon = "";
    this.ejecutivoErrorIcon = "";

    this.nombreErrorBorderColor = "";
    this.apellidoErrorBorderColor = "";
    this.cuitErrorBorderColor = "";
    this.emailErrorBorderColor = "";
    this.telefonoErrorBorderColor = "";
    this.companiaErrorBorderColor = "default";
    this.newpasswordErrorBorderColor = "";
    this.passwordErrorBorderColor = "";
    this.confirmacionErrorBorderColor = "";
    this.ejecutivoErrorBorderColor = "";

    // First validation
    if (!this.nombre) {
      this.nombreError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG;
    }
    if (!this.apellido) {
      this.apellidoError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG;
    }
    if (!this.cuit) {
      this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG;
    }
    if (!this.email) {
      this.emailError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG;
    }
    if (!this.telefono) {
      this.telefonoError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG;
    }
    if (!this.compania || this.compania <= 0) {
      this.companiaError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION;
    }
    // if (!this.password){
    //   this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    // }
    if (this.password && (this.newpassword !== this.confirmacion)) {
      this.newpasswordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORDDONTMATCH;
    }
    if ((this.newpassword || this.confirmacion) && !this.password) {
      this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_ISEMPTY;
    }

    if ((this.password && !this.newpassword) || (this.password && !this.confirmacion)) {
      this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG;
      this.newpasswordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG;
    }

    // if (this.password && (this.newpassword != this.confirmacion)) {
    //   this.newpasswordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORDDONTMATCH;
    // }

    // Special Validation
    if (!this.nombreError && this.nombre.length < 2) {
      this.nombreError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_2;
    }
    if (!this.apellidoError && this.apellido.length < 2) {
      this.apellidoError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_2;
    }
    if (!this.cuitError) {
      // Remove "-"
      this.cuit = (this.cuit) ? this.cuit.replace(/-/g, "") : "";

      // Validate data
      this.cuitValid = ValidarCuit(this.cuit);
      if (!this.cuitValid) {
        this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.CUIT_INVALID;
      }
    }
    if (!this.emailError && !checkEmail(this.email)) {
      this.emailError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMAIL_INVALID;
    }
    if (!this.telefonoError && this.telefono.length < 8) {
      this.telefonoError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_8;
    }

    if (this.password !== "" && !this.passwordError) {
      if (this.password.length < 6) {
        this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
      } else if (!checkPassword(this.password)) {
        this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_INVALID_PATTERN;
      }
    }

    if (this.password !== "" && !this.confirmacionError) {
      if (this.confirmacion.length < 6) {
        this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
      }
      if (this.newpassword.length < 6) {
        this.newpasswordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
      }
      else if (!checkPassword(this.newpassword) || !checkPassword(this.confirmacion)) {
        this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_INVALID_PATTERN;
        this.newpasswordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_INVALID_PATTERN;
      } else if (this.newpassword !== this.confirmacion) {
        this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_DONT_MATCH;
        this.newpasswordError = this.confirmacionError;
      }
    }

    this.ejecutivoError = ""; //No puede tener error (es opcional y una lista de seleccion)

    this.formError = this.nombreError || this.apellidoError || this.cuitError || this.emailError || this.telefonoError || this.companiaError || this.passwordError || this.newpasswordError || this.confirmacionError || this.ejecutivoError;
    if (this.formError) {
      this.nombreErrorIcon = (this.nombreError) ? InputError : InputCheck;
      this.nombreErrorBorderColor = (this.nombreError) ? "error" : "success";
      this.apellidoErrorIcon = (this.apellidoError) ? InputError : InputCheck;
      this.apellidoErrorBorderColor = (this.apellidoError) ? "error" : "success";
      this.cuitErrorIcon = (this.cuitError) ? InputError : InputCheck;
      this.cuitErrorBorderColor = (this.cuitError) ? "error" : "success";
      this.emailErrorIcon = (this.emailError) ? InputError : InputCheck;
      this.emailErrorBorderColor = (this.emailError) ? "error" : "success";
      this.telefonoErrorIcon = (this.telefonoError) ? InputError : InputCheck;
      this.telefonoErrorBorderColor = (this.telefonoError) ? "error" : "success";
      this.passwordErrorIcon = (this.passwordError) ? InputError : InputCheck;
      this.passwordErrorBorderColor = (this.passwordError) ? "error" : "success";
      this.newpasswordErrorIcon = (this.newpasswordError) ? InputError : InputCheck;
      this.newpasswordErrorBorderColor = (this.newpasswordError) ? "error" : "success";
      this.confirmacionErrorIcon = (this.confirmacionError) ? InputError : InputCheck;
      this.confirmacionErrorBorderColor = (this.confirmacionError) ? "error" : "success";
      this.ejecutivoErrorBorderColor = (this.ejecutivoError) ? "error" : "success";
      this.setState({ name: "" }, () => { window.scrollTo(0, 0); });
    } else {
      this.setState({ loadingForm: true }, () => {
        this.props.onUserConfigLoadToggle(this.state.loadingForm);
      });

      const requestOptions = {
        method: 'POST',
        headers: DEFAULT_HEADER.get(),
        body: JSON.stringify({
          User: userAuth.userId(),
          Name: this.nombre,
          LastName: this.apellido,
          CUIT: this.cuit,
          Email: this.email,
          Telephone: this.telefono,
          TelephoneCompany: this.compania,
          CurrentPassword: this.password,
          Password: this.newpassword,
          Avatar: this.state.cropsCounter > 0 ? this.state.userInfo.avatar : this.state.originalImage,
          AccountManagerId: this.ejecutivo
        })
      };

      return fetch(PRODUCERURL + "api/producer/UpdateProducerData", requestOptions)
        .then((response) => {
          return QLIBS.authorizeAction(response);
        })
        .then(this.handleResponse)
        .catch(this.handleResponse);
    }
  };

  handleResponse(response) {

    const { USER_CONFIG } = GLOBAL;

    let self = this;
    if (response && response.success) {
      let userInfo = response;

      self.formSuccess = true;
      self.readingMode = true;

      if (self.state.userInfo.email !== userInfo.email) {
        self.setState({ alert: USER_CONFIG.EMAIL_SENT, loadingForm: false }, () => {
          self.props.onUserConfigLoadToggle(self.state.loadingForm);
        });
      }
      else {
        self.setState({ alert: USER_CONFIG.MODIFIED_DATA, loadingForm: false }, () => {
          self.props.onUserConfigLoadToggle(self.state.loadingForm);
        });
      }

      setTimeout(function () {
        if (!userAuth.isUserSimulator()) {
          userAuth.setUserAvatar(response.avatar);
          userAuth.setUserName(self.nombre + ' ' + self.apellido);
        }
        window.location.reload(true);
      }, 3000);

    } else {
      this.formError = true;
      this.formErrorMessage = response.businessExceptions.map(x => x.message).join(". ");
      self.setState({ name: "", loadingForm: false }, () => {
        self.props.onUserConfigLoadToggle(self.state.loadingForm);
      });
    }
  }

  handleCancelClick = () => {
    this.readingMode = true;
    this.setState({ name: this.state.name });
  };

  render() {
    const { COMPONENTS_FORM } = GLOBAL;
    const { USER_PROFILE } = GLOBAL;

    return (
      <div className="container option-1">
        {this.state.showWarningSimulator && (<SimulatorWarning
          onDismiss={() => { this.setState({ showWarningSimulator: false }) }}
          onConfirm={() => { this.setState({ showWarningSimulator: false }, () => { document.getElementById('btnSubmit').click(); }) }}
        />)}
        <div className="row pt-4">
          {(this.formSuccess && this.state.alert) && (
            <div className="col-12 px-0">
              <Alert
                title={COMPONENTS_FORM.ALERT_SUCCESS_TITLE}
                message={this.state.alert}
                level={ErrorLevel.Success}
              />
            </div>
          )}
          {this.formError && (
            <div className="col-12 px-0">
              <Alert
                type="error"
                icon="times"
                title={COMPONENTS_FORM.ALERT_ERROR_TITLE}
                message={this.formErrorMessage}
                noDetail={true}
              />
            </div>
          )}
        </div>
        {this.state && this.state.userInfo &&
          <div className="row pl-md-5">
            <div className="col-lg-2 col-md-2 pt-3 text-md-left text-center">
              <ProfileBadge width="90px" height="90px" alt="Foto de perfil" image={this.state.userInfo.avatar} />
            </div>
            <div className="col-lg-7 col-md-5 pt-md-5 mt-lg-2 mt-1 pt-3 pl-md-5 pl-lg-1">
              <h4 className="text-center text-md-left option-1__Title">{this.state.userInfo.name} {this.state.userInfo.lastName}</h4>
            </div>
            {!this.readingMode && (
              <div className="col-lg-7 col-md-5 pt-md-5 mt-lg-2 mt-1 pt-3 pl-md-5 pl-lg-1">
                <Avatar
                  width={390}
                  height={295}
                  onCrop={this.onCrop}
                  onImageLoad={this.onImageLoad}
                  cropRadius={146}
                  src={this.state.userInfo.avatar}
                  label={USER_PROFILE.CHANGE_AVATAR_TEXT}
                />
              </div>
            )}
            <div className="col-lg-3 col-md-5 d-flex justify-content-center justify-content-md-end align-items-end pr-md-5">
              <Button type="button"
                customStyles={{ fontSize: '12px', padding: '7.8px 18px' }}
                color={this.readingMode ? 'primary' : 'editing'}
                onClick={this.setReadingMode}
                size="md">
                <i className="fas fa-edit mr-2" />Editar</Button>
            </div>
          </div>}
        {this.state && this.state.userInfo &&
          <form id="form-user-config" onSubmit={this.handleSubmit} className="register__Form" noValidate>
            <div className="row pl-md-5 pr-md-4 pt-4">
              <div className="col-md-6">
                <Input
                  id="nombre"
                  type="text"
                  text={USER_PROFILE.INPUTS.NAMES}
                  defaultValue={this.state.userInfo.name}
                  maxLength="50"
                  errorMsg={this.nombreError}
                  borderColor={this.nombreErrorBorderColor}
                  imgSrc={this.nombreErrorIcon}
                  onKeyDown={this.handleKeyDown}
                  onChange={this.handleChange}
                  disabled={this.readingMode}
                />
              </div>
              <div className="col-md-6">
                <Input
                  id="apellido"
                  type="text"
                  text={USER_PROFILE.INPUTS.LAST_NAMES}
                  defaultValue={this.state.userInfo.lastName}
                  maxLength="50"
                  errorMsg={this.apellidoError}
                  borderColor={this.apellidoErrorBorderColor}
                  imgSrc={this.apellidoErrorIcon}
                  onKeyDown={this.handleKeyDown}
                  onChange={this.handleChange}
                  disabled={this.readingMode}
                />
              </div>
            </div>
            <div className="row pl-md-5 pr-md-4">
              <div className="col-md-6">
                <Input
                  id="cuit"
                  type="text"
                  text={USER_PROFILE.INPUTS.CUIT}
                  defaultValue={this.state.userInfo.cuit.toString()}
                  mask="99-99999999-9"
                  errorMsg={this.cuitError}
                  borderColor={this.cuitErrorBorderColor}
                  imgSrc={this.cuitErrorIcon}
                  onChange={this.handleChange}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <Input
                  id="email"
                  type="text"
                  text={USER_PROFILE.INPUTS.EMAIL}
                  defaultValue={this.state.userInfo.email}
                  maxLength="100"
                  errorMsg={this.emailError}
                  borderColor={this.emailErrorBorderColor}
                  imgSrc={this.emailErrorIcon}
                  onKeyDown={this.handleKeyDown}
                  onChange={this.handleChange}
                  disabled={this.readingMode}
                />
              </div>
            </div>
            <div className="row pl-md-5 pr-md-4">
              <div className="col-md-6">
                <Input
                  id="telefono"
                  type="text"
                  text={USER_PROFILE.INPUTS.PHONE}
                  defaultValue={this.state.userInfo.telephone}
                  maxLength="15"
                  errorMsg={this.telefonoError}
                  borderColor={this.telefonoErrorBorderColor}
                  imgSrc={this.telefonoErrorIcon}
                  onKeyDown={this.handleKeyDown}
                  onChange={this.handleChange}
                  disabled={this.readingMode}
                />
              </div>
              <div className="col-md-6">
                <Select
                  id="compania"
                  text={USER_PROFILE.INPUTS.PHONE_COMPANY}
                  defaultValue={FORM_DATA.PHONE_COMPANIES.find(x => x.id === this.state.userInfo.telephoneCompany).value}
                  options={FORM_DATA.PHONE_COMPANIES}
                  errorMsg={this.companiaError}
                  status={this.companiaErrorBorderColor}
                  onChange={this.handleChange}
                  disabled={this.readingMode}
                />
              </div>
            </div>

            {!this.readingMode && (
              <>
                <hr />
                <div className="row pl-md-5 pr-md-4">
                  <div className="col-md-6">
                    <span><b><u>{USER_PROFILE.INPUTS.CHANGE_PASSWORD}
                    </u></b>
                    </span>
                  </div>
                  <div className="col-md-6">
                    <Input
                      id="password"
                      type="password"
                      text={USER_PROFILE.INPUTS.CURRENT_PASSWORD}
                      defaultValue=""
                      maxLength="30"
                      smallPrint={USER_PROFILE.PASS_INSTRUCTIONS_CHANGE}
                      errorMsg={this.passwordError}
                      borderColor={this.passwordErrorBorderColor}
                      imgSrc={this.passwordErrorIcon}
                      onKeyDown={this.handleKeyDown}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      id="newpassword"
                      type="password"
                      text={USER_PROFILE.INPUTS.NEW_PASSWORD}
                      defaultValue=""
                      maxLength="30"
                      smallPrint={USER_PROFILE.PASS_INSTRUCTIONS}
                      errorMsg={this.newpasswordError}
                      borderColor={this.newpasswordErrorBorderColor}
                      imgSrc={this.newpasswordErrorIcon}
                      onKeyDown={this.handleKeyDown}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <Input
                      id="confirmacion"
                      type="password"
                      text={USER_PROFILE.INPUTS.CONFIRM_PASSWORD}
                      defaultValue=""
                      maxLength="30"
                      smallPrint={USER_PROFILE.CONFIRM_INSTRUCTIONS}
                      errorMsg={this.confirmacionError}
                      borderColor={this.confirmacionErrorBorderColor}
                      imgSrc={this.confirmacionErrorIcon}
                      onKeyDown={this.handleKeyDown}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row pl-md-5 pr-md-4">
                  <div className="col-md-6" />
                  <div className="col-md-3 mb-2 mb-md-0">
                    <Button type="button" fill="outline" color="secondary" onClick={this.handleCancelClick}>{COMPONENTS_FORM.CANCEL_BUTTON}</Button>
                  </div>
                  <div className="col-md-3 mb-4 mb-md-0">
                    {userAuth.isUserSimulator() ? (
                      <>
                        <Button type="submit" id="btnSubmit" customStyles={{ display: 'none' }} />
                        <Button type="button" onClick={() => { this.setState({ showWarningSimulator: true }) }}>{COMPONENTS_FORM.OK_BUTTON}</Button>
                      </>
                    ) : (<Button type="submit">{COMPONENTS_FORM.OK_BUTTON}</Button>)}
                  </div>
                </div>
              </>
            )}
          </form>
        }
      </div>
    );
  }
}

export default UserConfig;