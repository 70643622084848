import { Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import GestionTramitesStep1 from '../../Resources/Images/Tutoriales/GestionTramites/GestionTramites-1.jpg';

import StepTutorial from './index-step';
function getSteps() {
    return ['Paso 1', 'Paso 2'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <StepTutorial image={GestionTramitesStep1} text={[
                'Seleccionar el sector y tipo de trámite a realizar',
                'Escribir un mensaje y el asunto del mismo',
                'Si es necesario, agregar un archivo adjunto',
                'Si se desea, seleccionar “Recibir copia en mi e-mail”',
                'Hacer click en “Enviar consulta” para que sea recibida por el sector correspondiente'
            ]} />
        default:
            return '¡Tutorial completado!';
    }
}

export function GestionTramites({ activeStep, setSteps }) {
    const steps = getSteps();

    useEffect(() => {
        setSteps(steps);
    }, [setSteps, steps]);
    return (
        <>
            <h2>Gestión de trámites</h2>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <Typography>{getStepContent(activeStep)}</Typography>
            </div>
        </>
    );
}