import React, { useEffect, useState } from "react";
import userAuth from '../../Config/userauth';
import { Header } from "../../Components";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LabelIcon from '@material-ui/icons/Label';
import { useLocation } from 'react-router-dom';
import { Login } from "./login";
import { Cartera } from "./cartera";
import { Registracion } from "./registracion";
import { Canales } from "./canales";
import { Liquidacion } from "./liquidacion";
import { Caucion } from "./caucion";
import { Rechazo } from "./rechazo";
import { EnvioFacturas } from "./envio-facturas";
import { Certificados } from "./certificado-retencion";
import { GestionTramites } from "./gestionTramites";
import { Deudores } from "./deudores";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '800px',
        width: '100%',
        display: 'flex',
        height: '92vh'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    sidebar: {
        padding: theme.spacing(2),
        height: '100%',
        backgroundColor: '#00b3b5',
        color: 'white',
    },

    stepperContainer: {
        padding: theme.spacing(2),
    },
}));


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export function Tutoriales() {
    const [activeSection, setActiveSection] = React.useState('registration');
    const query = useQuery();
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        const seccion = query.get('seccion');
        if (seccion) {
            setActiveSection(seccion);
        }
    }, []);

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const renderComponent = () => {
        switch (activeSection) {
            case 'como-registrarse':
                return <Registracion activeStep={activeStep} setSteps={setSteps} />;
            case 'como-ingresar':
                return <Login activeStep={activeStep} setSteps={setSteps} />;
            case 'cartera':
                return <Cartera activeStep={activeStep} setSteps={setSteps} />;
            case 'canales':
                return <Canales activeStep={activeStep} setSteps={setSteps} />;
            case 'liquidacion':
                return <Liquidacion activeStep={activeStep} setSteps={setSteps} />;
            case 'caucion':
                return <Caucion activeStep={activeStep} setSteps={setSteps} />;
            case 'rechazo':
                return <Rechazo activeStep={activeStep} setSteps={setSteps} />;
            case 'envio-facturas':
                return <EnvioFacturas activeStep={activeStep} setSteps={setSteps} />;
            case 'certificado-retencion':
                return <Certificados activeStep={activeStep} setSteps={setSteps} />;
            case 'gestion-tramites':
                return <GestionTramites activeStep={activeStep} setSteps={setSteps} />;
            case 'deudores':
                return <Deudores activeStep={activeStep} setSteps={setSteps} />;
            default:
                return <div>404 Not Found</div>;
        }
    };
    return (
        <>
            {(userAuth.isAuthenticated() === false) && (
                <Header />
            )}
            <Grid container className={classes.root}>
                <Grid item xs={3}>
                    <Paper className={classes.sidebar}>
                        <Typography variant="h6">Tutoriales</Typography>
                        <Divider />
                        <MenuList>
                            <MenuItem onClick={() => setActiveSection('como-registrarse')}>
                                <ListItemIcon>
                                    <LabelIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Registración</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => setActiveSection('como-ingresar')}>
                                <ListItemIcon>
                                    <LabelIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit" >Ingresar</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => setActiveSection('cartera')}>
                                <ListItemIcon>
                                    <LabelIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Cartera</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => setActiveSection('liquidacion')}>
                                <ListItemIcon>
                                    <LabelIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Liquidación</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => setActiveSection('canales')}>
                                <ListItemIcon>
                                    <LabelIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Canales</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => setActiveSection('caucion')}>
                                <ListItemIcon>
                                    <LabelIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Caución</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => setActiveSection('rechazo')}>
                                <ListItemIcon>
                                    <LabelIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Rechazo</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => setActiveSection('envio-facturas')}>
                                <ListItemIcon>
                                    <LabelIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Envío de facturas</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => setActiveSection('certificado-retencion')}>
                                <ListItemIcon>
                                    <LabelIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Certificados de retención</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => setActiveSection('gestion-tramites')}>
                                <ListItemIcon>
                                    <LabelIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Gestión de trámites</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => setActiveSection('deudores')}>
                                <ListItemIcon>
                                    <LabelIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Deudores por premio</Typography>
                            </MenuItem>
                        </MenuList>
                    </Paper>
                </Grid>

                <Grid item xs={9} className={classes.stepperContainer}>
                    <div>
                        {renderComponent()}
                    </div>
                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <Button onClick={handleReset}>Reiniciar</Button>
                            </div>
                        ) : (

                            <div className={classes.buttons}>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.backButton}
                                >
                                    Volver
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Terminar' : 'Siguiente'}
                                </Button>
                            </div>

                        )}
                    </div>
                </Grid>
            </Grid>
        </>
    );
}