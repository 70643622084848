import { GLOBAL } from "../Config/global";

const userAuth = {
  storage: sessionStorage,
  getStorage() {
    this.storage = (!sessionStorage.length && localStorage.length) ? localStorage : sessionStorage;
    return this.storage;
  },
  setStorage(remember) {
    localStorage.clear();
    sessionStorage.clear();
    this.storage = localStorage;
    this.storage.setItem('rememberMe', remember);
    this.storage.setItem('active', true);
  },
  isAuthenticated() {
    var usrid = this.userId();
    var authenticated = (usrid != null && usrid.length > 0);
    return authenticated;
  },
  authenticate(user, cb) {
    this.getStorage().setItem('userId', user.userId);
    this.getStorage().setItem('cuit', user.cuit);
    this.getStorage().setItem('reportTo', user.reportTo_Cuit);
    this.getStorage().setItem('userAvatar', user.avatar);
    this.getStorage().setItem('userName', user.userType.toString() === GLOBAL.USER_TYPE_ADMINISTRADOR ? 'Usuario Administrador' : user.userFirstName + ' ' + user.userLastName);
    this.getStorage().setItem('user', user.userName);
    this.getStorage().setItem('userType', user.userType.toString());
    this.getStorage().setItem('userEmailConfirmed', user.emailConfirmed);
    this.getStorage().setItem('userCode', user.code);
    this.getStorage().setItem('rol', user.rol);
    this.getStorage().setItem('cantidadFacturasPendientes', user.sysData?.cantidadFacturasPendientes);
    this.getStorage().setItem('cantidadFacturasPendientesPermitidas', user.sysData?.cantidadFacturasPendientesPermitidas);
    this.getStorage().setItem('descripcionTipoDePago', user.sysData?.descripcionTipoDePago);
    this.getStorage().setItem('emails', user.sysData?.emails);
    cb();
  },
  cuit() {
    return this.getStorage().cuit;
  },
  userId() {
    let userId;
    if (this.requestSelectProducer() === false && this.userTypeId() === GLOBAL.USER_TYPE_SIMULADOR) {
      userId = this.getStorage().userIdProducer;
    }
    else {
      userId = this.getStorage().userId;
    }
    return userId;
  },
  simulatorUserId() {
    //return this.requestSelectProducer() === false ? this.getStorage().userId : null;
    return this.getStorage().userId;
  },
  isGestor(){
    try {
      return this.getStorage().rol === 'Gestor'; 
    } catch (error) {
      return false;
    }
   
  },
  reportTo() {
    return this.getStorage().reportTo;
  },
  userTypeId() {
    return this.getStorage().userType;
  },
  userAvatar() {
    return this.getStorage().userAvatar;
  },
  setUserAvatar(avatar) {
    this.getStorage().setItem('userAvatar', avatar);
  },
  userName() {
    return this.getStorage().userName;
  },
  rol(){
    return this.getStorage().rol;
  },
  setUserName(userName) {
    this.getStorage().setItem('userName', userName);
  },
  user() {
    return this.getStorage().user;
  },
  userIdProducer() {
    return this.getStorage().userIdProducer;
  },
  userNameProducer() {
    return this.getStorage().userNameProducer;
  },
  cuitProducer() {
    return this.getStorage().cuitProducer;
  },
  setUserIdProducer(id, name, cuit, sysData, reportTo_Cuit,rol) {
    this.getStorage().setItem('userIdProducer', id);
    this.getStorage().setItem('userNameProducer', name);
    this.getStorage().setItem('cuitProducer', cuit);
    this.getStorage().setItem('reportTo', reportTo_Cuit);
    this.getStorage().setItem('rol', rol);
    this.getStorage().setItem('cantidadFacturasPendientes', sysData?.cantidadFacturasPendientes);
    this.getStorage().setItem('cantidadFacturasPendientesPermitidas', sysData?.cantidadFacturasPendientesPermitidas);
    this.getStorage().setItem('descripcionTipoDePago', sysData?.descripcionTipoDePago);
    this.getStorage().setItem('emails', sysData?.emails);
  },
  getAppVersion() {
    const storage = this.getStorage();
    return (storage != null) ? storage.appVersion : null;
  },
  setAppVersion(appVersion) {
    let storage = this.getStorage();
    if (storage != null) storage.setItem('appVersion', appVersion);
  },
  isProducer() {
    return this.userTypeId() === GLOBAL.USER_TYPE_PRODUCTOR;
  },
  isUserSimulator() {
    return this.userTypeId() === GLOBAL.USER_TYPE_SIMULADOR;
  },
  isAdmin() {
    return this.userTypeId() === GLOBAL.USER_TYPE_ADMINISTRADOR;
  },
  requestSelectProducer() {
    return this.isUserSimulator() === true && this.cuitProducer() === undefined;
  },
  userCode() {
    return this.getStorage().userCode;
  },
  requestChangePassword() {
    return this.getStorage().userEmailConfirmed === "false" && this.isUserSimulator();
  },
  setUserEmailConfirmed(value) {
    this.getStorage().setItem('userEmailConfirmed', value);
  },
  getGiccpData(){
    return { 
      descripcionTipoDePago: this.getStorage().descripcionTipoDePago, 
      cantidadFacturasPendientesPermitidas : this.getStorage().cantidadFacturasPendientesPermitidas,
      cantidadFacturasPendientes : this.getStorage().cantidadFacturasPendientes
     }
  },
  cleanSimulator() {
    this.getStorage().removeItem('userIdProducer');
    this.getStorage().removeItem('userNameProducer');
    this.getStorage().removeItem('cuitProducer');
    this.getStorage().removeItem('menuAdvanced');
    this.getStorage().removeItem('menu-selected');
  },
  remember() {
    return this.getStorage().userId !== undefined && this.getStorage().rememberMe;
  },
  signout(cb) {
    if (this.remember()) {
      this.getStorage().removeItem('userId');
    } else {
      this.getStorage().clear();
    }
    cb();
  }
};

export default userAuth;