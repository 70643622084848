import { Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";

import CanalesStep1 from '../../Resources/Images/Tutoriales/Caucion/Caucion-1.jpg';
import StepTutorial from './index-step';
function getSteps() {
    return ['Paso 1'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return  <StepTutorial image={CanalesStep1} text={['Hacer clic en “Caución” para ser redirigido al portal']} />
       default:
        return '¡Tutorial completado!';
    }
}

export function Caucion({ activeStep, setSteps }) {
    const steps = getSteps();

    useEffect(() => {
        setSteps(steps);
    }, [setSteps, steps]);
    return (
        <>
            <h2>Caución</h2>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <Typography>{getStepContent(activeStep)}</Typography>
            </div>
        </>
    );
}