import { Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";

import loginStep1 from '../../Resources/Images/Tutoriales/Login/login-1.jpg';
import loginStep2 from '../../Resources/Images/Tutoriales/Login/login-2.jpg';
import StepTutorial from './index-step';
function getSteps() {
    return ['Paso 1', 'Paso 2'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <StepTutorial image={loginStep1} text={[
                <span>Ingresar a <a href='http://productores.lifeseguros.com.ar/'>http://productores.lifeseguros.com.ar/</a>,
                    escribir el CUIT y contraseña que generó en el registro al sistema </span>,
                <span>Presionar el botón <b>Ingresar</b></span>]} />;
        case 1:
            return <StepTutorial image={loginStep2} text={[
                "Si los datos ingresados son correctos, se redireccionará al portal web PAS",
                <span>En caso que no recuerde su contraseña, acceda a
                    <a href='http://productores.lifeseguros.com.ar/recover-password' > http://productores.lifeseguros.com.ar/recover-password </a>
                    para poder reestablecerla</span>]} />;
        default:
            return '¡Tutorial completado!';
    }
}

export function Login({ activeStep, setSteps }) {
    const steps = getSteps();

    useEffect(() => {
        setSteps(steps);
    }, [setSteps, steps]);
    return (
        <>
            <h2>Cómo iniciar sesión</h2>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <Typography>{getStepContent(activeStep)}</Typography>
            </div>
        </>
    );
}