/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
	Alert,
	Breadcrumb,
	Button,
	Input,
	Loading,
	ProducerSelector,
	Table as TableComponent,
	TabsPanel,
	Combobox,
	Checkbox,
	SchedulerModal,
	ViewsModal,
	LabelSection
} from "../../Components";
import 'react-dates/initialize';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { GLOBAL } from "../../Config/global";
import "font-awesome/css/font-awesome.min.css";
import { APPCONFIG } from '../../app.config';
import moment from "moment";
import { loadProducerCode } from '../../Config/producerData'
import { truncate } from "../../Shared/Utils";
import userAuth from "../../Config/userauth";
import * as QLIBS from '../../qLibsHelpers';
import { DEFAULT_HEADER } from '../../Config/userToken';
import { ErrorLevel } from '../../Components/Alert/errorLevel';

class Deudores extends React.Component {
	constructor(props) {
		const { COMMON_SEARCH_FORM } = GLOBAL;
		super(props);
		this.filterTabs = [
			{
				label: COMMON_SEARCH_FORM.SELECT_PRODUCERS_TAB
			},
			{
				label: COMMON_SEARCH_FORM.SELECT_DATES_TAB
			},
			{
				label: COMMON_SEARCH_FORM.SELECT_PRODUCTS_TAB
			},
			{
				label: COMMON_SEARCH_FORM.SELECT_POLICY_TAB
			}
		];

		const autoexecPolicyExpired = (props.location.pathname === "/deudores-policyExpired");
		this.state = {
			ramos: [],
			filters: [],
			sort: [],
			productos: [],
			periodos: [],
			name: '',
			checkedItems: [],
			productorSelected: 0,
			showViewsModal: false,
			enableFilterTabs: false,
			autoexecPolicyExpired: autoexecPolicyExpired, //determina si se tiene que ejecutar automaticamente la consulta al inicio
			onlyPolicyExpired: autoexecPolicyExpired, //determina si se aplica el filtro
			defaultProducer: null,
			loadingData: false,
			searchEnabled: false,
			filtersSelected: false,
			filterTabActive: 0,
			searchCriteria: [
				{
					"productores": []
				},
				{
					"vigencia": QLIBS.getPeriod()
				},
				{
					"ramo": '',
					"producto": ''
				},
				{
					"poliza": null,
					"certificado": null
				},
				{
					"nombre": null,
					"apellido": null,
					"dni": null
				}
			],
			searchCriteriaText: "",
			productoresData: [],
			showSchedulerModal: false,
			iconFilter: 'fa fa-eye',
			showFilters: false
		};

		this.baseTableColumns = this.createBaseTableColumns();
		this.searchTableColumns = this.createSearchTableColumns();

		this.producerSelector = React.createRef();
		this.showResults = false;
		this.searchResultsError = "";
		this.page = 0;
		this.pageSize = 10;
		this.downloadItem = this.downloadItem.bind(this);
		this.onChangeTab = this.onChangeTab.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.clearFiltros = this.clearFiltros.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.handleProducerIdChanged = this.handleProducerIdChanged.bind(this);
		this.handleCheckedItemsChanged = this.handleCheckedItemsChanged.bind(this);
		this.handleProducersChanged = this.handleProducersChanged.bind(this);
		this.handleSaveViewModal = this.handleSaveViewModal.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
	}

	async componentDidMount() {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		QLIBS.fillCombo('ramos', PRODUCERURL + 'api/datos/GetComboValues?e=Ramo_Debtors', this, GLOBAL.COMBO_EMPTY_ITEM, true);
		QLIBS.fillCombo('productos', PRODUCERURL + 'api/datos/GetComboValues?e=Producto_Debtors', this, GLOBAL.COMBO_EMPTY_ITEM, true);

		//fillCombo periodo
		const d1 = new Date(2021, 7, 1); //cuando se inicio el modulo
		let d2 = new Date(); //hasta la actualidad
		d2.setDate(d2.getDate() - 1); //resto un dia

		const periodRange = QLIBS.getPeriodRange(d1, d2, true);
		const dataCombo = periodRange.map(period => ({ id: period, value: QLIBS.getPeriodName(period) }));
		this.setState({ periodos: dataCombo }, () => { });

		var userId = userAuth.userId();
		const requestOptions = {
			method: 'GET',
			//headers: { 'Content-Type': 'application/json' }
			headers: DEFAULT_HEADER.get()
		};

		const response = await fetch(PRODUCERURL + "api/producer/GetProducerCodes?userId=" + userId, requestOptions);
		const data = await response.json();
		let defaultProducer = null;
		var uc = data;
		for (var u in uc) {
			var item = uc[u];
			if (this.state.autoexecPolicyExpired) {
				if (defaultProducer === null && item.Core === "VT") {
					defaultProducer = item.ProducerCodes[0].Code;
				}
			}
			for (var p in item.producerCodes) {
				var pc = item.producerCodes[p];
				pc.name = pc.name.split("|")[0];
			}
		}
		if (defaultProducer !== null)
			this.setState({ defaultProducer: defaultProducer });
		this.handleProducersChanged(loadProducerCode(uc));
		if (this.state.autoexecPolicyExpired) {
			this.setState({ autoexecPolicyExpired: false },
				() => {
					this.producerSelector.current.setProductorDefault(this.state.defaultProducer);
				});
		}
	}

	handleExportSubmit(event) {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		event.preventDefault();
		this.setState({ loadingData: true });

		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: JSON.stringify({
				producerId: this.state.searchCriteria[0].productores.map(x => parseFloat(x.id.replace('X', ''))),
				vigencia: this.state.searchCriteria[1].vigencia.toString(),
				ramo: this.state.searchCriteria[2].ramo,
				producto: this.state.searchCriteria[2].producto,
				poliza: this.state.searchCriteria[3].poliza,
				certificado: this.state.searchCriteria[3].certificado,
				columns: JSON.stringify(this.createExcelReportColumns())
			})
		};

		fetch(`${PRODUCERURL}debtors/export`, requestOptions)
			.then(function (resp) {
				if (resp.ok)
					return resp.blob();
			})
			.then(function (blob) {
				if (blob) {
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = "Deudores.xlsx";
					link.click();
				}
			})
			.then(() => {
				this.setState({ loadingData: false });
			})
	};


	downloadItem = event => {
		const pdfUrl = event.row._original.pdfUrl;
		window.open(pdfUrl, "_blank");
	};

	onChangeTab = index => {
		this.setState({ name: this.state.name, filterTabActive: index, filtersSelected: (index > 0) });
	};

	handleKeyDown = event => {
		// Chek which field
		switch (event.target.id) {
			case "nro_poliza":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
					event.preventDefault();
				}
				break;

			case "nro_certificado":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
					event.preventDefault();
				}
				break;

			case "nombre":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
					event.preventDefault();
				}
				break;

			case "apellido":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
					event.preventDefault();
				}
				break;

			case "dni":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
					event.preventDefault();
				}
				break;

			default:
				// Do Nothing
				break;
		}
	}

	handleComboChange = (selectedOption, event) => {
		let inputName = event.name;
		let inputValue = selectedOption.value;
		let searchCriteria = this.state.searchCriteria;

		switch (inputName) {
			case "ramo":
				searchCriteria[2].ramo = inputValue;
				break;
			case "producto":
				searchCriteria[2].producto = inputValue;
				break;
			case "vigencia":
				searchCriteria[1].vigencia = inputValue;
				break;

			default:
				break;
		}
		this.setState({
			searchCriteria: searchCriteria
		}, () => {
			this.refreshFiltros();
		});
	};

	handleCheckboxChange = event => {
		this.setState({
			onlyPolicyExpired: event.target.checked
		}, () => {
			this.refreshFiltros(() => { this.handleSubmit(event); });
		});
	}

	handleChange = event => {
		let searchCriteria = this.state.searchCriteria;
		// Check which field
		switch (event.target.id) {

			case "nro_poliza":
				searchCriteria[3].poliza = event.target.value;
				break;

			case "nro_certificado":
				searchCriteria[3].certificado = event.target.value;
				break;

			case "nombre":
				searchCriteria[4].nombre = event.target.value;
				break;

			case "apellido":
				searchCriteria[4].apellido = event.target.value;
				break;

			case "dni":
				searchCriteria[4].dni = event.target.value;
				break;

			default:
				break;
		}

		this.setState({
			searchCriteria: searchCriteria
		}, () => {
			//
			this.refreshFiltros();
		});
	};

	refreshFiltros = (callbackStateReady = null) => {
		const { COMMON_SEARCH_FORM } = GLOBAL;
		// Populate Filtros
		let searchCriteriaText = COMMON_SEARCH_FORM.SELECTED_PRODUCERS;

		// Add productores
		const filter = {};

		// creo dinamicamente el objeto para el filtro de acuerdo al filtro seleccionado
		if (this.state.searchCriteria[0].productores.length > 0) {
			filter.ProducerId = {};
			filter.ProducerId.in = [];
			filter.Core = {};
			filter.Core.in = [];
		} else {
			delete filter.ProducerId;
			delete filter.Core;
		}

		let productores_id = Array.from(new Set(this.state.searchCriteria[0].productores.map((item) => item.id.replace('X', ''))));
		let productores_core = Array.from(new Set(this.state.searchCriteria[0].productores.map((item) => item.core)));
		let productores_text = Array.from(new Set(this.state.searchCriteria[0].productores.map((item) => item.text)));

		productores_text.forEach((item, index) => {
			searchCriteriaText += (index) ? ", " : "";
			searchCriteriaText += item;

			// agrego para la clausula in ProducerId
			let id = parseInt(productores_id[index]);
			if (id > 0)
				filter.ProducerId.in.push(id);

			let core = productores_core[index];
			if (core)
				filter.Core.in.push(core);
		});

		if (this.state.searchCriteria[2].ramo) {
			filter.BranchCode = {};
			filter.BranchCode.in = [];
			this.state.searchCriteria[2].ramo.split(';').forEach(function (value, index) {
				filter.BranchCode.in.push(parseInt(value));
			});
		} else {
			delete filter.BranchCode;
		}

		if (this.state.searchCriteria[2].producto) {
			filter.ProductCode = parseInt(this.state.searchCriteria[2].producto);
		} else {
			delete filter.ProductCode;
		}

		if (this.state.searchCriteria[3].poliza) {
			filter.PolicyNumber = parseInt(this.state.searchCriteria[3].poliza);
		} else {
			delete filter.PolicyNumber;
		}

		if (this.state.searchCriteria[3].certificado) {
			filter.CertificateNumber = parseInt(this.state.searchCriteria[3].certificado);
		} else {
			delete filter.CertificateNumber;
		}

		if (this.state.searchCriteria[4].nombre || this.state.searchCriteria[4].apellido) {
			filter.RecruiterName = {};
			filter.RecruiterName.contains = "";
			filter.RecruiterName.contains += (this.state.searchCriteria[4].nombre) ? " " + this.state.searchCriteria[4].nombre : "";
			filter.RecruiterName.contains += (this.state.searchCriteria[4].apellido) ? " " + this.state.searchCriteria[4].apellido : "";
		} else {
			delete filter.RecruiterName;
		}

		if (this.state.searchCriteria[4].dni) {
			filter.RecruiterDni = {};
			filter.RecruiterDni.contains = this.state.searchCriteria[4].dni;
		} else {
			delete filter.RecruiterDni;
		}

		//Add Period
		if (this.state.searchCriteria[1].vigencia) {
			let idvigencia = this.state.searchCriteria[1].vigencia;
			let vigencia = this.state.periodos.filter(x => x.id === idvigencia);
			if (vigencia.length > 0)
				searchCriteriaText += "<br>Vigencia: " + vigencia[0].value;
		}

		if (this.state.searchCriteria[2].ramo || this.state.searchCriteria[2].producto) {
			let idramo = this.state.searchCriteria[2].ramo;
			let ramo = '';
			if (idramo) {
				ramo = this.state.ramos.filter(x => x.id === idramo)[0].value;
			}
			let idproducto = this.state.searchCriteria[2].producto;
			let producto = '';
			if (idproducto) {
				producto = this.state.productos.filter(x => x.id === idproducto)[0].value;
			}

			searchCriteriaText += "<br>" + COMMON_SEARCH_FORM.PRODUCTS_SEARCH_TEXT;
			searchCriteriaText += (ramo !== '') ? " Ramo \"" + ramo + "\"" : "";
			searchCriteriaText += ((producto !== '' && ramo !== '') ? ", " : "") + ((producto !== '') ? " Producto \"" + producto + "\"" : "");
		}

		if (this.state.searchCriteria[3].poliza || this.state.searchCriteria[3].certificado) {// || this.searchCriteria[3].estado
			searchCriteriaText += "<br>" + COMMON_SEARCH_FORM.POLICY_SEARCH_TEXT;
			searchCriteriaText += (this.state.searchCriteria[3].poliza) ? " Póliza \"" + this.state.searchCriteria[3].poliza + "\"" : "";
			searchCriteriaText += ((this.state.searchCriteria[3].certificado && this.state.searchCriteria[3].poliza) ? ", " : "") + ((this.state.searchCriteria[3].certificado) ? " Certificado \"" + this.state.searchCriteria[3].certificado.toString() + "\"" : "");
		}

		if (this.state.searchCriteria[4].nombre || this.state.searchCriteria[4].apellido || this.state.searchCriteria[4].dni) {
			searchCriteriaText += "<br>" + COMMON_SEARCH_FORM.USER_SEARCH_TEXT;
			searchCriteriaText += (this.state.searchCriteria[4].nombre) ? " Nombre \"" + this.state.searchCriteria[4].nombre + "\"" : "";
			searchCriteriaText += ((this.state.searchCriteria[4].apellido && this.state.searchCriteria[4].nombre) ? ", " : "") + ((this.state.searchCriteria[4].apellido) ? " Apellido \"" + this.state.searchCriteria[4].apellido + "\"" : "");
			searchCriteriaText += ((this.state.searchCriteria[4].dni && (this.state.searchCriteria[4].nombre || this.state.searchCriteria[4].apellido)) ? ", " : "") + ((this.state.searchCriteria[4].dni) ? " Dni \"" + this.state.searchCriteria[4].dni + "\"" : "");
		}

		const vigencia = QLIBS.getPeriodPart(this.state.searchCriteria[1].vigencia);
		const year = vigencia[0];
		const month = vigencia[1];
		const fechaDesde = new Date(year, month - 1, 1); //el mes del periodo es igual al index del mes de la fecha + 1, por eso le resto 1
		const fechaHasta = new Date(year, month, 1);
		fechaHasta.setDate(fechaHasta.getDate() - 1); //me lleva al ultimo dia del mes anterior

		this.setState(
			{
				name: this.state.name,
				searchCriteriaText: searchCriteriaText
			}, () => {
				if (callbackStateReady !== null) {
					callbackStateReady();
				}
			});
	};

	clearFiltros = () => {
		var date = new Date(), y = date.getFullYear(), m = date.getMonth();

		const SEARCH_CRITERIA_DEFAUL_VALUES = [
			{
				"productores": []
			},
			{
				"vigencia": QLIBS.getPeriod()
			},
			{
				"ramo": '',
				"producto": ''
			},
			{
				"poliza": null,
				"certificado": null,
			},
			{
				"nombre": null,
				"apellido": null,
				"dni": null
			}
		];


		document.getElementById('ramo').value = '';
		document.getElementById('producto').value = '';
		document.getElementById('nro_poliza').value = '';
		document.getElementById('nro_certificado').value = '';
		document.getElementById('nombre').value = '';
		document.getElementById('apellido').value = '';
		document.getElementById('dni').value = '';
		document.getElementById('vigencia').value = '';

		this.showResults = false;
		this.searchResultsError = false;
		this.producerSelector.current.resetSelection();

		this.setState({
			searchCriteria: SEARCH_CRITERIA_DEFAUL_VALUES,
			checkedItems: [],
			productorSelected: 0,
			enableFilterTabs: false,
			searchEnabled: false,
			filterTabActive: 0
		}, () => {
			this.refreshFiltros();
		});
	};

	handleSubmit = async (event) => {
		if (event)
			event.preventDefault();
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		this.searchResultsError = "";
		this.showResults = false;
		let sort = this.state.sort.length === 0 ? null : this.state.sort[0];
		this.setState({ name: this.state.name, filterTabActive: -1, loadingData: true });
		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: JSON.stringify({
				gridFilters: this.state.filters,
				next: this.pageSize,
				sort: sort,
				offset: this.page * this.pageSize,
				producerId: this.state.searchCriteria[0].productores.map(x => parseFloat(x.id.replace('X', ''))),
				vigencia: this.state.searchCriteria[1].vigencia.toString(),
				ramo: this.state.searchCriteria[2].ramo,
				producto: this.state.searchCriteria[2].producto,
				poliza: this.state.searchCriteria[3].poliza,
				certificado: this.state.searchCriteria[3].certificado,
			})
		};
		let url = new URL(`${PRODUCERURL}debtors/`);
		return fetch(url, requestOptions)
			.then((response) => {
				return QLIBS.authorizeAction(response);
			})
			.then(this.handleResponse)
			.catch(this.handleResponse);
	};

	handleCheckedItemsChanged(checkedItems, defaultSetted = false) {
		let searchCriteria = this.state.searchCriteria;
		searchCriteria[0].productores = checkedItems;

		let callbackStateReady = (defaultSetted) ? () => { this.handleSubmit(); } : null;
		this.setState({
			checkedItems: checkedItems,
			enableFilterTabs: checkedItems.length > 0,
			searchCriteria: searchCriteria,
			searchEnabled: checkedItems.length > 0
		}, () => {
			this.refreshFiltros(callbackStateReady);
		});
	};

	handleProducerIdChanged(producerId) {
		this.setState({ productorSelected: producerId });
	};

	handleProducersChanged(newProducers) {
		this.setState({ productoresData: newProducers });
	};

	handleProducersDefaultReady(code) {

	};

    applyFilter = (event) => {
        if (event.length > 0) {
            this.page = 0
            this.setState({
                filters: event,
            },this.handleSubmit);
        }
        else {
            this.setState({
                filters: [],
            }, this.handleSubmit);
        }
    }

	handleResponse = (response) => {
		const { COMMON_SEARCH_FORM } = GLOBAL;
		let tableData = [];
		if (Array.isArray(response.data)) {
			this.searchResultsError = "";
			this.showResults = true;
			this.pages = Math.ceil(response.total / this.pageSize);
			var id = 0;
			tableData = response.data.map(function (item) {
				id++;
				return {
					recruiterName: item.recruiterName,
					expirationDate: item.expirationDate == null ? '-' : moment(item.expirationDate).format("DD/MM/YYYY"),
					daysExpiredAmount: item.daysExpiredAmount,
					outstandingBalance: `$ ${truncate(item.outstandingBalance, 2)}`,
					expired30Days: `$ ${truncate(item.expired30Days, 2)}`,
					expired60Days: `$ ${truncate(item.expired60Days, 2)}`,
					expired90Days: `$ ${truncate(item.expired90Days, 2)}`,
					expiredGreaterThen90Days: `$ ${truncate(item.expiredGreaterThen90Days, 2)}`,
					toExpire: `$ ${truncate(item.toExpire, 2)}`,
					productCode: item.productCode,
					productDescription: item.productDescription,
					branchCode: item.branchCode,
					branchDescription: item.branchDescription,
					reference: item.reference,
					policyNumber: (item.policyNumber).toString(),
					certificateNumber: item.certificateNumber,
					receiptNumber: item.receiptNumber,
					paymentMethod: item.paymentMethod,
					recruiterDni: item.recruiterDni,
					producerId: item.producerId,
					producerDescription: item.producerDescription,
					issueDate: item.issueDate == null ? '-' : moment(item.issueDate).format("DD/MM/YYYY"),
					startDate: item.startDate == null ? '-' : moment(item.startDate).format("DD/MM/YYYY"),
					endDate: item.endDate == null ? '-' : moment(item.endDate).format("DD/MM/YYYY"),
					reward: `$ ${truncate(item.reward, 2)}`,
					premium: `$ ${truncate(item.premium, 2)}`,
					cutDate: item.cutDate == null ? '-' : moment(item.cutDate).format("DD/MM/YYYY"),
					initCertificateDate: item.initCertificateDate == null ? '-' : moment(item.initCertificateDate).format("DD/MM/YYYY"),
					initAnnuityDate: item.initAnnuityDate == null ? '-' : moment(item.initAnnuityDate).format("DD/MM/YYYY"),
					lastPaymentDate: item.lastPaymentDate == null ? '-' : moment(item.lastPaymentDate).format("DD/MM/YYYY"),
					currency: item.currency,
					changeFactor: item.changeFactor,
					stateCertificate: item.stateCertificate,
					anio_mes: item.yearMonth
				}
			});
		} else {
			this.searchResultsError = COMMON_SEARCH_FORM.SERVER_ERROR;
			this.showResults = true;
		}

		this.searchTableColumns = this.createSearchTableColumns();

		this.setState({ data: tableData, name: this.state.name, loadingData: false });
	};

	handleSaveViewModal(newListColumns) {
		const tempTableColumns = this.createBaseTableColumns();

		this.baseTableColumns = newListColumns.map(c => {
			let col = tempTableColumns.find(f => f.name === c.name);
			col.show = c.show;
			return col;
		});

		this.searchTableColumns = this.createSearchTableColumns();
	}

	getFilter() {
		let filters = this.state.filters
		return filters
	}
	getSort() {
		let sort = this.state.sort
		return sort
	}
	applySort = (event) => {
		if (event.length > 0) {
			this.setState({
				sort: event,
			}, () => this.handleSubmit());
		}
		else {
			this.setState({
				sort: {},
			}, this.handleSubmit);
		}
	}
	changeSize = async (size) => {
		this.pageSize = size;
		this.handleSubmit()
	}
	changePage = async (page) => {
		this.page = page;
		this.handleSubmit()
	}

	onKeyDown = (event) => {
		// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
		if (event.key === 'Enter' && this.state.searchEnabled) {
			event.preventDefault();
			event.stopPropagation();
			document.getElementById("btnSubmit").click();
		}
	}

	createSearchTableColumns() {
		const newTableColumns = [...this.baseTableColumns.filter(f => f.show)];
		return newTableColumns;
	}

	createExcelReportColumns() {
		const excelReportColumns = this.baseTableColumns.filter(f => f.show).map(c => {
			return {
				Title: c.title,
				Field: c.field,
				Summarize: c.summarize,
				Format: c.format
			}
		});
		return excelReportColumns;
	}

	createBaseTableColumns() {
		const { REPORT_FORMAT } = GLOBAL;

		let newTableColumns = [];

		// Get Columns identifier
		let columns = {
			"recruiterName": { title: "Contratante", field: "RecruiterName", show: true, width: 200 },
			"expirationDate": { title: "Fecha de vencimiento", field: "ExpirationDate", format: REPORT_FORMAT.FECHA, show: true, width: 150 },
			"daysExpiredAmount": { title: "Cant días vencido", field: "DaysExpiredAmount", show: true },
			"outstandingBalance": { title: "Saldo pendiente", field: "OutstandingBalance", format: REPORT_FORMAT.currency, show: true, summarize: true, signo: '$' },
			"expired30Days": { title: "Vencido a 30 días", field: "Expired30Days", format: REPORT_FORMAT.currency, show: true, summarize: true, signo: '$' },
			"expired60Days": { title: "Vencido a 60 días", field: "Expired60Days", format: REPORT_FORMAT.currency, show: true, summarize: true, signo: '$' },
			"expired90Days": { title: "Vencido a 90 días", field: "Expired90Days", format: REPORT_FORMAT.currency, show: true, summarize: true, signo: '$' },
			"expiredGreaterThen90Days": { title: "Vencido más de 90 días", field: "ExpiredGreaterThen90Days", format: REPORT_FORMAT.currency, show: true, summarize: true, signo: '$' },
			"toExpire": { title: "A vencer", field: "ToExpire", format: REPORT_FORMAT.currency, show: true, summarize: true, signo: '$' },
			"productCode": { title: "Producto Código", field: "ProductCode", show: true },
			"productDescription": { title: "Producto descripción", field: "ProductDescription", show: true },
			"branchCode": { title: "Ramo código", field: "BranchCode", show: true },
			"branchDescription": { title: "Ramo descripción", field: "BranchDescription", show: true },
			"policyNumber": { title: "Póliza", field: "PolicyNumber", show: true },
			"certificateNumber": { title: "Certificado", field: "CertificateNumber", show: true },
			"receiptNumber": { title: "Recibo", field: "ReceiptNumber", show: true },
			"paymentMethod": { title: "Medio de pago", field: "PaymentMethod", show: true },
			"recruiterDni": { title: "DNI contratante", field: "RecruiterDni", show: true },
			"producerId": { title: "Productor código", field: "ProducerId", show: true },
			"producerDescription": { title: "Productor descripción", field: "ProducerDescription", show: true },
			"issueDate": { title: "Fecha de emisión", field: "IssueDate", format: REPORT_FORMAT.FECHA, show: true },
			"startDate": { title: "Vigencia desde", field: "StartDate", format: REPORT_FORMAT.FECHA, show: true },
			"endDate": { title: "Vigencia hasta", field: "EndDate", format: REPORT_FORMAT.FECHA, show: true },
			"reward": { title: "reward", field: "Reward", format: REPORT_FORMAT.currency, show: true, summarize: true, signo: '$' },
			"premium": { title: "premium", field: "Premium", format: REPORT_FORMAT.currency, show: true, summarize: true, signo: '$' },
			"cutDate": { title: "Fecha de corte", field: "CutDate", format: REPORT_FORMAT.FECHA, show: true },
			"initCertificateDate": { title: "Fecha inicio certificado", field: "InitCertificateDate", format: REPORT_FORMAT.FECHA, show: true },
			"initAnnuityDate": { title: "Fecha inicio anualidad", field: "InitAnnuityDate", format: REPORT_FORMAT.FECHA, show: true },
			"lastPaymentDate": { title: "Fecha último pago", field: "LastPaymentDate", format: REPORT_FORMAT.FECHA, show: true },
			"currency": { title: "currency", field: "Currency", show: true },
			"stateCertificate": { title: "Estado certificado", field: "StateCertificate", show: true },
		};

		// Add Columns to Table
		let cols = QLIBS.getTableColumns(columns);
		cols.forEach((c) => { newTableColumns.push(c) });

		return newTableColumns;
	}

	render(...props) {
		const { USER_DASHBOARD: { LOGOUT_MODAL }, TABLE_VIEW } = GLOBAL;
		const { LIQUIDACION_SEARCH_FORM, COMMON_SEARCH_FORM, PAGES } = GLOBAL;

		// Set Tabs disabled
		this.filterTabs[1].disabled = (!this.state.enableFilterTabs);
		this.filterTabs[2].disabled = (!this.state.enableFilterTabs);
		this.filterTabs[3].disabled = (!this.state.enableFilterTabs);

		return (
			<>
				{this.state.showViewsModal && <ViewsModal
					listColumns={this.baseTableColumns}
					onDismiss={() => { this.setState({ showViewsModal: false }) }}
					onSave={(listColumns) => { this.handleSaveViewModal(listColumns) }}
				/>}
				{this.state.showSchedulerModal && (<SchedulerModal reporte="Deudores" api={`debtors/export`} productores={this.state.searchCriteria[0].productores.map((item) => { return item.id.replace('X', '') })} cores={this.state.searchCriteria[0].productores.map((item) => { return item.core })} dismiss={() => { this.setState({ showSchedulerModal: false }) }} />)}
				<section className="col-12">
					<div className="row">
						<div className="col-12">
							<Breadcrumb currentPage="/deudores" />
						</div>
						<div className="col-12">
							<h4 className="form-title">{PAGES.TITLE.DEUDORES}</h4>
						</div>
						<div className="col-12 mb-2">
							<LabelSection section="deudores" type="subtitulo" className="form-title" />
						</div>
						<div className="form-container container table-view col-md-12 mt-2">
							<form id="deudores_search_form1" onSubmit={this.handleSubmit} onKeyDown={this.onKeyDown} className="register__Form" noValidate>
								<div className="row">
									<div className="col-12">
										<TabsPanel options={this.filterTabs} tabActive={this.state.filterTabActive}
											onChange={this.onChangeTab}>
											<div id="tab1" className="container option-1">
												<ProducerSelector producers={this.state.productoresData}
													ref={this.producerSelector}
													onCheckedItemsChanged={this.handleCheckedItemsChanged}
													onProducerIdChanged={this.handleProducerIdChanged}
													onProducersChanged={this.handleProducersChanged}
												/>
											</div>
											<div id="tab2" className="container option-2">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-6">
																<Combobox
																	id="vigencia"
																	label={COMMON_SEARCH_FORM.VIGENCIA}
																	options={this.state.periodos.map((item) => ({ value: item.id, label: item.value }))}
																	onChange={(v, e) => { this.handleComboChange(v, e); }}
																	defaultValue={this.state.searchCriteria[1].vigencia}
																/>
																<label style={{ fontSize: 14 }}>
																	El presente informe muestra los datos procesados con la siguiente fecha de corte: el último día hábil del período seleccionado
																</label>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div id="tab3" className="container option-3">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-6">
																<Combobox
																	id="ramo"
																	label={COMMON_SEARCH_FORM.RAMO}
																	options={this.state.ramos?.map((item) => ({ value: item.id, label: item.value }))}
																	onChange={(v, e) => { this.handleComboChange(v, e); }}
																	defaultValue={this.state.searchCriteria[2].ramo}
																/>
															</div>
															<div className="col-md-6">
																<Combobox
																	id="producto"
																	label={COMMON_SEARCH_FORM.PRODUCTO}
																	options={this.state.productos?.map((item) => ({ value: item.id, label: item.value }))}
																	onChange={(v, e) => { this.handleComboChange(v, e); }}
																	defaultValue={this.state.searchCriteria[2].producto}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div id="tab4" className="container option-4">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-6">
																<Input
																	id="nro_poliza"
																	type="text"
																	text={COMMON_SEARCH_FORM.NRO_POLIZA}
																	defaultValue={this.state.searchCriteria[3].poliza}
																	classes="mb-0"
																	maxLength="50"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-6">
																<Input
																	id="nro_certificado"
																	type="text"
																	text={COMMON_SEARCH_FORM.NRO_CERTIFICADO}
																	defaultValue={this.state.searchCriteria[3].certificado}
																	classes="mb-0"
																	maxLength="50"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>

														</div>
													</div>
												</div>
											</div>
											<div id="tab5" className="container option-5">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-4">
																<Input
																	id="nombre"
																	type="text"
																	text={COMMON_SEARCH_FORM.ASEGURADO.NOMBRE}
																	defaultValue={this.state.searchCriteria[4].nombre}
																	maxLength="100"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-4">
																<Input
																	id="apellido"
																	type="text"
																	text={COMMON_SEARCH_FORM.ASEGURADO.APELLIDO}
																	defaultValue={this.state.searchCriteria[4].apellido}
																	maxLength="100"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-4">
																<Input
																	id="dni"
																	type="text"
																	text={COMMON_SEARCH_FORM.ASEGURADO.DNI}
																	defaultValue={this.state.searchCriteria[4].dni}
																	classes="mb-0"
																	maxLength="8"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</TabsPanel>
									</div>
								</div>

								{(this.state.productorSelected > 0) && (this.state.checkedItems.length <= 0) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												level={ErrorLevel.Success}
												icon="info"
												title={COMMON_SEARCH_FORM.PRODUCTOR_SELECTED_ALERT}
												message=""
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected > 0) && (this.state.checkedItems.length > 0) && (!this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												level={ErrorLevel.Success}
												icon="info"
												title={COMMON_SEARCH_FORM.START_SEARCH_ALERT}
												message=""
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected > 0) && (this.state.checkedItems.length > 0) && (this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												level={ErrorLevel.Success}
												icon="info"
												title={COMMON_SEARCH_FORM.SET_FILTER_ALERT}
												message=""
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected <= 0) && (!this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												level={ErrorLevel.Success}
												icon="info"
												title={COMMON_SEARCH_FORM.SELECT_ALERT}
												message=""
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected <= 0) && (!this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<div className="alert-modal text-center text-lg-left text-light" role="alert">
												<div className="d-md-inline">
													<LabelSection section="deudores" type="aviso" className="alert-modal-info alert-modal-info-label" />
												</div>
											</div>
										</div>
									</div>
								)}
								{(this.state.productorSelected <= 0) && (this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												type="error"
												icon="info"
												title={COMMON_SEARCH_FORM.SELECT_PRODUCER_ALERT}
												message=""
												noDetail={true}
											/>
										</div>
									</div>
								)}


								{(this.state.searchEnabled) && (
									<div className="row mt-3">
										<div className="col-12">
											<div className="search-buttons">
												<Button type="submit" id="btnSubmit" classes="to-bottom button-small search-button"><span className="fa fa-search"> </span> {COMMON_SEARCH_FORM.SEARCH_BUTTON}</Button>
											</div>
											<div className="search-buttons">
												<Button type="button" classes="to-bottom button-small search-button" onClick={this.clearFiltros}><span className="fa fa-filter"> </span> {COMMON_SEARCH_FORM.CLEAR_FILTERS}</Button>
											</div>
											{(this.state.searchEnabled && this.showResults) && (
												<div className="search-buttons">
													<Button type="submit" onClick={event => this.handleExportSubmit(event)} size="small"><i className="fas fa-file-download mx-2" /> {TABLE_VIEW.EXPORT_TEXT}</Button>
												</div>
											)}
											{APPCONFIG.ENABLESCHEDULER === "true" && (
												<div className="search-buttons">
													<Button type="button" classes="to-bottom button-small search-button" onClick={() => { this.setState({ showSchedulerModal: true }) }}><span className="fa fa-clock-o"> </span> {COMMON_SEARCH_FORM.SCHEDULER_BUTTON}</Button>
												</div>
											)}
											<div className="search-buttons">
												<Button type="button" classes="to-bottom button-small search-button" onClick={() => { this.setState({ showViewsModal: true }) }}><span className="fa fa-table"> </span> {COMMON_SEARCH_FORM.VIEW_BUTTON}</Button>
											</div>
										</div>
									</div>
								)}
								{(this.state.productorSelected > 0 && this.state.checkedItems.length > 0 && this.state.showFilters) && (
									<div className="row">
										<div className={`col-12 mt-3`}>
											<Alert
												errorDesc={this.state.searchCriteriaText}
												errorInfo=""
												errorLine=""
												icon="info"
												message={` ${this.state.productoresData[this.state.productorSelected - 1].text}`}
												title={COMMON_SEARCH_FORM.APPLIED_FILTERS_ALERT}
												type="message"
												details={true}
											/>
										</div>
									</div>
								)}
								{this.searchResultsError && (
									<div id="form-error-message" className="row my-2">
										<div className="col-12">
											<Alert
												icon="times"
												message=""
												title={this.searchResultsError}
												errorInfo={this.searchResultsError}
												type="error"
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected > 0 && this.state.checkedItems.length > 0) && (
									<div id="form-error-message" className="row my-2">
										<div className="col-12">
											<Checkbox
												id="chkOnlyPolicyExpired"
												checked={this.state.onlyPolicyExpired}
												fontSize="smaller-text"
												text="Ver sólo pólizas próximas a anularse"
												onChange={this.handleCheckboxChange}
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected > 0 && this.state.checkedItems.length > 0 && this.state.onlyPolicyExpired) && (
									<div id="form-error-message" className="row my-2">
										<div className="col-12">
											<Alert
												type="success"
												icon="info"
												title={COMMON_SEARCH_FORM.ONLY_POLICY_EXPIRED_ALERT}
												message=""
											/>
										</div>
									</div>
								)}
								{(this.state.data) && (
									<div className="row my-3">
										<div className="col-12 px-3">
											<TableComponent
												loading={this.state.loadingData}
												columns={this.searchTableColumns}
												sorted={this.getSort()}
												onSortedChange={this.applySort}
												filter={this.getFilter()}
												data={this.state.data}
												manual={true}
												onFilterChange={this.applyFilter}
												page={this.page}
												pageSize={this.pageSize}
												onPageSizeChange={this.changeSize}
												pages={this.pages}
												changePage={this.changePage}
												{...props}
											/>
										</div>
									</div>
								)}
							{(!this.state.data) && (
									<Loading visible={this.state.loadingData}></Loading>
								)}
							</form>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default Deudores;